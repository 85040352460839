

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MbscScrollViewOptions, mobiscroll } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LaService } from 'src/app/modules/la/servises/la.service';
import { UserService } from 'src/app/services/user/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { Subscription } from 'rxjs';




@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.css'],
  providers: [DatePipe],

})
export class InvoiceDetailComponent implements OnInit {

  scrollViewOptions: MbscScrollViewOptions = {
    layout: 'fixed',
    itemWidth: 300,
    snap: false
};
base_amount:any;


quota:any = {total:50,disponible:0,utilizado:0};
mounto_pendiente_vencido:any = 0;
mounto_pendiente:any = 0;
shops:any = [];
shops_obj:any = {};


buro: any;
loading_buro = false;

  loading: any = true;
  isAuth: any;
  sessionUser: any;
  person: any;
  imgPerson: any;
  usQuote: any = false;

  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  installments:any;
  installmentNexPayment:any;
  invoiceCredit:any;
  tp:any = 2;
  mounto_por_conciliar:any = 0;

  invoiceCredits:any = [];
  queryParams:any={};

  private socketServiceSubscribe:any  = Subscription;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    public _auth : AuthService,

  ) {
    
  }

  

  ngOnDestroy(): void {
    this.socketServiceSubscribe.unsubscribe();
  }

  ngOnInit(): void {

    this.getBase_amount();


    this.activatedRoute.params.subscribe(params => {
      this.queryParams = params;
      if (this.queryParams?.code) {
        
        this._auth.auth$.subscribe((rep: any) => {
          this.isAuth = rep;
          if (rep) {
            this.getPerson();
          }
        });
    
      }
      

    });

    this.sessionUser = this._auth.sessionUser;

  

    this.socketService.resetDataClientCode.subscribe((person_code: any) => {
      if(person_code && person_code == this._auth.sessionUser?.person_code){

        this.getSection_transaction_by_person();

      }
    });
    
  

   this.socketServiceSubscribe =  this.socketService.msgDataClient.subscribe((payload: any) => {

      if(payload?.code_person == this._auth?.sessionUser?.person_code  && payload?.type ==  'CREATE_INVOICE'){
       this.getPerson(false);
       }
       
     });
     this.getshops();
  }




getshops = async () => {
  try {

    const resp = await this.getshopsServer();
    this.shops = resp?.res;

    let shops_resp = resp?.res;
    let shops:any = {};


    shops_resp.map((value:any)=>{

      shops[value.code] = value?.name;

    });

    this.shops_obj = shops;
    
    
  } catch (error) {
    console.error(error);

  } 

 


}


getshopsServer = async () => {
  try{
      return this._user.getshops({});

    } catch (error) {
      console.error(error);
      
      
    }
}


  getPerson = async (loadinge = true) => {

    try {
    
    const resp = await this._user.getPersonbyCode({ code: this._auth.sessionUser?.person_code });
    this.person = resp.resp;

    let total= this.person?.person?.current_quota? this.person?.person?.current_quota : this.base_amount;
    this.quota =  {
        total:total,
        utilizado:this.toFixedApp(total - this.person?.person?.quota ),
        disponible:this.toFixedApp(this.person?.person?.quota)
    };


    if(localStorage.getItem('active_cupo') && this.person?.person?.quota_active){

      this.toogleStatus(true); 
      localStorage.removeItem('active_cupo');

    }

    

    if (this.person?.person) {

        //this.getSectionTransactions();
       // this.getSection_transaction_by_person();

        const resp_invoiceCredits = await this.listClientInvoiceCredits();
        let temp =  resp_invoiceCredits.res;

        let invoiceCredits:any = [];
        let installMent_promes:any =[]

        temp?.forEach((element:any) => {
          if(element?.code == this.queryParams?.code ){

            invoiceCredits = [...invoiceCredits,element];

            const data =  {
              code_invoice:element?.code 
             
             }
    
             installMent_promes  =  [...installMent_promes,this._user.getPersonInstallmentsInvoiceCredits(data)] ;
  
          }

        });

        let installMents_base = await Promise.all(installMent_promes);


        this.getInvoicesAndInstallments(invoiceCredits,installMents_base);

       //this.invoiceCredits =  invoiceCredits;
    }
  
    this.imgPerson = this._auth.sessionUser?.file_selfie?.url;
      this.usQuote =  this.person?.person?.quota_client_active ? true:false;

    } catch (error) {
      console.error(error);

    }
  }

  

  getInvoicesAndInstallments = async (invoiceCredits_base:any,installMents_base:any) => {
    try {
        
      this.invoiceCredits  = invoiceCredits_base?.map((invoice:any, index:any) => {
        
        let installments:any = installMents_base[index]?.res;

        let installmentNexPayment:any ;

        let totalPaymentConcile = 0;

      
         installments.map((value:any) => {

          value?.payments?.forEach((value2:any)=>{

            if(value2.payment.reconciled_payment){

              totalPaymentConcile = this.toFixedApp( totalPaymentConcile +  value2.payment.amount);

            }

          });

          value["totalpaymentReference"] = 0;
  
          value?.paymentReference?.forEach((value2:any) =>{
            value["totalpaymentReference"] =  value["totalpaymentReference"]  +  value2?.payment?.amount;
          });


          if(value?.installMent?.payment_status?.code != 'PaymentComplete' && !installmentNexPayment){
            installmentNexPayment = value?.installMent;
          }

        
          
        


          if(value?.installMent?.payment_status?.code != 'PaymentComplete' &&  value?.expired){
            this.mounto_pendiente_vencido = this.mounto_pendiente_vencido + value.installMent.dueBalance ;
          }

          if(value?.installMent?.payment_status?.code != 'PaymentComplete'){
            this.mounto_pendiente = this.mounto_pendiente + value.installMent.dueBalance ;
          }
          
          this.mounto_por_conciliar =this.toFixedApp(  this.mounto_por_conciliar + value?.totalpaymentReference );

          return value;
        });

        invoice.installMent = installments;
        invoice.installmentNexPayment = installmentNexPayment;
        invoice.totalPaymentConcile = totalPaymentConcile;

        return invoice;

      });

      this.invoiceCredit = this.invoiceCredits?.length > 0 ?this.invoiceCredits[0] : null;

      } catch (error) {
        console.error(error);
        
      }

      this.loading = false;

  }




  listClientInvoiceCredits = async () => {
       
    try {
         const data =  {
          code_person :this._auth.sessionUser?.person_code
         
         }

        return this._user.listClientInvoiceCredits(data);

      } catch (error) {
          console.error(error);
        
        
      }
  }



  getSection_transaction_by_person = async (buro:any = true) => {

    try {

    const resp = await this._user.getSection_transaction_by_person({ code: this._auth.sessionUser?.person_code,type:'ONBOARDING' });

    const result = resp.resp;
    let temp: any = {};

    if (result?.section_transactions?.length > 0) {

      result?.section_transactions.map((obj: any) => {

        temp[obj?.section_transaction?.code] = obj;

        if (obj?.section_transaction?.code == "BURO" && obj.condition == "COMPLETE" && buro) {
            this.getDataBuro(this.person, false);
          }
        return obj;
      });


    }

    
    this.section_transaction = temp;
    this.transaction = result?.transaction;

    this.loading = false;

   
  } catch (error) {
    this.loading = false;

    console.log(error);
    
      
  }
  }


  getDataBuro = async (item: any, reload = true) => {
    this.loading_buro = true;

    try {

         if (item?.identitys?.length > 0) {
      let cde = item?.identitys[0]?.code_identity?.code == 'V'? 1: 3;
      let params = {
        "type_identity": cde,
       // "type_identity": item?.identitys[0]?.type_identity?.additionalType,
        "identity": item?.identitys[0]?.identity,
        "lastName": item?.person?.lastName,
        "code_person": item?.person?.code
      }

      const buro = await this._user.getDataBuro(params);
      this.loading_buro = false;

      this.buro = buro?.resp;


      if (reload) {
        this.getSection_transaction_by_person(true);
      }


    }

        
    } catch (error) {
        this.loading_buro = false;
  
    }
   
  }





  getSectionTransactions = async () => {

    let filter ={type:'ONBOARDING'}

    const resp = await this._user.getSectionTransactions(filter);
    this.section_transactions = resp.resp;



  }
  

  toogleStatus = async (st = this.usQuote) => {
    
    let status = st?true:false;


    try {
      let query = {status};

      const resp = await this._user.toogleStatus(query);

      this.person.person = resp.res;
      this.usQuote = resp.res.quota_client_active;

    } catch (error) {
      this.usQuote = !status;
      console.log(error);
      
    }


  }

  bard(a:any,b:any){

     
let resto =  this.toFixedApp( this.toFixedApp( this.toFixedApp(a) *  this.toFixedApp(100)) /  this.toFixedApp(b));
let result =   this.toFixedApp(this.toFixedApp(100 )- this.toFixedApp((resto)));
return result;
  }

  toFixedApp = (x:any) => {

    let a:any = `e+${2}`;
    let b:any = `e-${2}`;
  
    return +(Math.round(x + a) + b);
  
    //return Number(Number.parseFloat(String((x + Number.EPSILON))).toFixed(TOFIXED));
    
  }
  
  restab(a:any,b:any){

    return  this.toFixedApp( a - b);

  }


  getBase_amount = async () =>  {

    try {
  
      let response =  await this._user.getBase_amount();
      this.base_amount = response?.amount;
  
    } catch (error) {
  
      console.log(error);
      
      
    }
    
  }
}
