<ng-template #optonsAcciones let-offcanvas>
    <div class="offcanvas-header">
        <div class=" w-100">
            <div class="col-12  ">
                <div class="row">
                    <div class="col-10">
                        <div class=" h5 text-secondary mt-2 ">
                            Seleccione la cuenta
                        </div>

                    </div>
                    <div class="col-2 text-end pt-2">
                        <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
                    </div>

                </div>

            </div>

        </div>
    </div>
    <div class="offcanvas-body">
        <div class="row mb-3">
            <div class="col-12  ">

                <ng-container *ngIf="accountPayment_load ">
                    <div class="w-100 text-center text-md-start">
                        <img src="./assets/public/images/loading.gif  " alt=" ">
                    </div>
                </ng-container>

                <div class="mt-2 " *ngIf="!accountPayment_load && accountPayment?.length < 1">
                    No se encontraron resultados
                </div>
                

                <div *ngIf="!accountPayment_load && accountPayment?.length > 0">
                    <div *ngFor="let item of accountPayment">
                        <div *ngIf="item?.payment_method?.code == dataPost?.tpp?.code " [ngClass]="{'active': account?.code == item?.code}" class="card mt-4 pb-2">

                            <div class="card-body card-instru p-2 cursor-pounter" (click)="selectAccount(item)">
                                <ng-container>
                                    <div class="titule-card-accion  fz-14 text-capitalize   fw-bold w-100 text-primary ">
                                        {{item?.bank?.name}}
                                    </div>

                                    <div class="d-flex mt-2" if>
                                        <div class="text-secondary  fz-12">
                                            Tipo
                                        </div>
                                        <div class="fw-bold fz-14 ms-auto">
                                            {{item?.payment_method?.name}}
                                        </div>
                                    </div>

                                    <div class="d-flex mt-1" if>
                                        <div class="text-secondary  fz-12">
                                            Moneda
                                        </div>
                                        <div class="fw-bold fz-14 ms-auto">
                                            {{item?.currency?.name}}
                                        </div>
                                    </div>
                                    <div class="d-flex mt-1" *ngIf="item?.taxId">
                                        <div class="text-secondary  fz-12">
                                            Rif
                                        </div>
                                        <div class="fw-bold fz-14 ms-auto">
                                            {{item?.taxId}}
                                        </div>
                                    </div>
                                    <div class="d-flex mt-1" *ngIf="item?.number">
                                        <div class="text-secondary  fz-12">
                                            Número
                                        </div>
                                        <div class="fw-bold fz-14 ms-auto">
                                            {{item?.number}}
                                        </div>
                                    </div>

                                    <div class="d-flex mt-1" *ngIf="item?.phone">
                                        <div class="text-secondary  fz-12">
                                            Teléfono
                                        </div>
                                        <div class="fw-bold fz-14 ms-auto">
                                            {{item?.phone}}
                                        </div>
                                    </div>

                                    <div class="d-flex mt-1" *ngIf="item?.email">
                                        <div class="text-secondary  fz-12">
                                            Email
                                        </div>
                                        <div class="fw-bold fz-14 ms-auto">
                                            {{item?.email}}
                                        </div>
                                    </div>

                                </ng-container>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

</ng-template>
<ng-template #view1 let-data>

    <div class="text-start mt-4 mb-3" *ngIf="installment">

        <h3 class="text-dark fw-bold mt-2">
            <i (click)="sectionTrans = 1" class="fas fa-angle-left pe-2"></i> Confirmar pago
        </h3>

        <small> {{account?.bank?.name}} </small> (
        <small class="text-secondary ">
               
            {{account?.payment_method?.name}}      
            </small>)
    </div>


    <form *ngIf="!rate_error" autocomplete="nope" novalidate [formGroup]="form" class="text-start">
        <div class="row mt-2">

            <ng-container *ngIf="account">
                <div class="form-group  col-12  mt-3">
                    <div class="form-floating ">
                        <input currencyMask (click)="moveCursorToEnd($event)" [options]="{ prefix:account?.currency?.code+' ', thousands: ',', decimal: '.' }" type="text" (keyup)="changeMount()" [ngClass]="{'error': form.controls.total_amount.invalid && form.controls.total_amount.dirty}"
                            class="form-control text-capitalize" id="floatingmt" formControlName="total_amount">
                        <label for="floatingmt" class=" label font-weight-normal" style="word-wrap: break-word;">
              <span >Monto  </span>
              
             </label>
                    </div>


                    <div class="form-control-feedback help-block" *ngIf="form.get('total_amount').errors">
                        <div *ngIf="form.get('total_amount').errors['required'] && !form.pristine">
                            Campo requerido
                        </div>

                        <div *ngIf="form.get('total_amount').errors['pattern']  && !form.pristine">
                            Monto inválido
                        </div>
                    </div>


                    <div class="form-control-feedback help-block" *ngIf="maxDuelbalance()">

                        <div>
                            Monto máximo superado
                        </div>

                    </div>

                    <div class="form-control-feedback help-block" *ngIf="!(form?.getRawValue()?.total_amount > 0) && !form.pristine">
                        Monto debe ser mayor a 0
                    </div>


                    <!-- <div class="form-control-feedback help-block" *ngIf="pec() && !form.pristine">
                        <b> Monto Exedido</b> <br> En caso de haber excedido pago del monto total de la cuota, dicho monto sera aplicado a otra cuota faltante cuando sea conciliado dicho pago.
                    </div> -->

                </div>



                <div class="form-group  col-12  mt-4 small text-secondary" *ngIf="dataPost?.tpp?.code == 'TRANSFERENCIA'">
                    <label for="floatingref">Identificación del dueño de la cuenta</label>
                </div>


                <div class="form-group  col-4  mt-1" *ngIf="dataPost?.tpp?.code == 'TRANSFERENCIA'">
                    <div class="form-floating">
                        <select [ngClass]="{'error': form.controls.code_identity.invalid && form.controls.code_identity.dirty}" class="form-select" id="code_identity" formControlName="code_identity" aria-label="Floating label select example">
                            <option  value="">Selecione</option>
                            <option  value="V">V</option>
                            <option  value="J">J</option>
                            <option  value="E">E</option>
                            <option  value="P">P</option>
                         </select>
                        <label for="code_identity">Tipo</label>
                    </div>

                    <div class="form-control-feedback help-block" *ngIf="form.get('code_identity').errors">
                        <div *ngIf="form.get('code_identity').errors['required'] && !form.get('code_identity').pristine">
                            Campo requerido
                        </div>
                    </div>
                </div>

                <div class="form-group  col-8  mt-1" *ngIf="dataPost?.tpp?.code == 'TRANSFERENCIA'">

                    <div class="form-floating ">
                        <input type="number" [ngClass]="{'error': form.controls.identity.invalid && form.controls.identity.dirty}" class="form-control text-capitalize" id="identity" formControlName="identity">
                        <label for="identity" class=" label font-weight-normal" style="word-wrap: break-word;">
                <span >Documento </span>
                 </label>
                    </div>

                </div>

                <div *ngIf="dataPost?.tpp?.code == 'TRANSFERENCIA' && account?.bank?.name != 'Zelle'" class="form-group  col-12  mt-4">
                    <label for="floatingref">Últimos 6 digitos del número de cuenta desde donde realizo el pago</label>
                </div>

                <div *ngIf="dataPost?.tpp?.code == 'TRANSFERENCIA' && account?.bank?.name != 'Zelle'" class="form-group  col-12  mt-3">

                    <div class="form-floating mb-2">
                        <input (keyup)="validateAccountNumber()" [ngClass]="{'error': form.controls.accountNumber.invalid && form.controls.accountNumber.dirty}" formControlName="accountNumber" type="text" class="form-control" id="floatingref" placeholder="name@example.com">

                        <label for="floatingref">
                           
                            <span>
                                Número de cuenta (últimos 6)
                            </span>
                        </label>
                    </div>

                    <div class="form-control-feedback help-block" *ngIf="form.get('accountNumber').errors">
                        <div *ngIf="form.get('accountNumber').errors['required'] && !form.get('accountNumber').pristine">
                            Campo requerido
                        </div>
                    </div>

                    <div *ngIf="!form.pristine && !validationAccountNumber" class="form-control-feedback help-block ">

                        Ingrese los últimos 6 digitos del número de cuenta
                    </div>


                </div>


                <div *ngIf="dataPost?.tpp?.code == 'PAGO_MOVIL'" class="form-group  col-12  mt-4 mb-1 text-secondary">
                    <label for="floatingref">Teléfono desde donde realizo el pago</label>
                </div>

                <!-- && dataPost?.tpp?.name != 'DEPOSITO_BS' && dataPost?.tpp?.name == 'DEPOSITO_USD' -->
                <div *ngIf="dataPost?.tpp?.code == 'PAGO_MOVIL'" class="form-group  col-4  mt-1">
                    <div class="form-floating">

                        <select [ngClass]="{'error': form.controls.code_phone.invalid && form.controls.code_phone.dirty}" class="form-select" id="code_phone" formControlName="code_phone" aria-label="Floating label select example">
                            <option  value="">Selecione</option>
                            <option  value="0414">0414</option>
                            <option  value="0424">0424</option>
                            <option  value="0412">0412</option>
                            <option  value="0416">0416</option>
                            <option  value="0426">0426</option>
                         </select>

                        <label for="code_phone">código</label>
                    </div>

                </div>

                <div *ngIf="dataPost?.tpp?.code == 'PAGO_MOVIL'" class="form-group  col-8  mt-1">

                    <div class="form-floating ">
                        <input (keyup)="validatePhone()" type="text" [ngClass]="{'error': form.controls.phone.invalid && form.controls.phone.dirty}" class="form-control text-capitalize" id="identity" formControlName="phone" placeholder="0000000">
                        <label for="phone" class=" label font-weight-normal" style="word-wrap: break-word;">
                        <span >Número </span>
                     </label>
                    </div>

                </div>


                <div *ngIf="dataPost?.tpp?.code == 'PAGO_MOVIL' &&  !form.pristine && !validationPhone" class="form-control-feedback help-block ">

                    Número telefónico del dueño de la cuenta bancaria desde donde hizo el pago

                </div>


                <div class="form-group  col-12  mt-4">

                    <div class="form-floating mb-2">
                        <ng-container  *ngIf="account?.bank?.name == 'Zelle'" >
                            <input [ngClass]="{'error': form.controls.reference.invalid && form.controls.reference.dirty}" formControlName="reference" type="text" class="form-control" id="floatingref"  placeholder="name@example.com">
                        </ng-container>

                        <ng-container  *ngIf="account?.bank?.name != 'Zelle'" >
                            <input (keypress)="validateNumericInput($event); validateNumberReference(this.reference_length, this.reference_length_max)"  (keyup)="validateNumericInput($event); validateNumberReference(this.reference_length, this.reference_length_max)" [ngClass]="{'error': form.controls.reference.invalid && form.controls.reference.dirty}" formControlName="reference" type="text" class="form-control" id="floatingref" [minlength]="reference_length"
                            [maxlength]="reference_length_max" placeholder="name@example.com" [pattern]="'^\\d*$'">
                        </ng-container>


                        
                        

                        <label for="floatingref">
                            <span  *ngIf="account?.bank?.name == 'Zelle'" >
                                Concepto colocado al realizar en la transacción
                            </span>
                            
                            <span  *ngIf="(account?.bank?.name != 'Zelle' ) ">
                                <span>
                                    Ultimos 8 digitos de la referencia del pago                                </span>
                            </span>

                            
                        </label>
                    </div>

                    <div class="form-control-feedback help-block" *ngIf="form.get('reference').errors">
                        <div *ngIf="form.get('reference').errors['required'] && !form.get('reference').pristine">
                            Campo requerido
                        </div>
                        <!-- <div *ngIf="form.controls.reference.errors?.minlength">Debe tener al menos {{reference_length}} caracteres. Si la referencia se coloca incompleta, deben colocarse los últimos digitos.</div> -->
                        <div *ngIf="form.controls.reference.errors?.maxlength">No puede tener más de {{reference_length_max}} caracteres.</div>
                        <div *ngIf="form.controls.reference.errors?.pattern">Solo se permiten números.</div>
                    </div>

                    <!-- <div *ngIf=" account?.bank?.name != 'Zelle' && !form.pristine && !validationReference" class="form-control-feedback help-block ">

                        Ingrese la referencia completa
                    </div> -->

                </div>

            </ng-container>
        </div>
    </form>

    <ng-container *ngIf="account">

        <div class="d-block d-md-none mt-4">
            <mbsc-date (onSet)="dateSet($event)" [(ngModel)]="date_base" [options]="mobileSettings" placeholder="dd/mm/aaaa">Fecha del pago</mbsc-date>
        </div>
        <div class="d-none d-md-block mt-4">
            <mbsc-date (onSet)="dateSet($event)" [(ngModel)]="date_base" [options]="desktopSettings" placeholder="dd/mm/aaaa">Fecha del pago</mbsc-date>
        </div>

        <div *ngIf="rate_error" class="text-danger small p-1 ">
            Error al consultar tasa (BCV) a esta fecha
        </div>

    </ng-container>


    <ng-container *ngIf="account && !rate_error">

        <div class=" mt-4">
            <mbsc-input [options]="mobileSettingsFile" (change)="onFileSelected($event)" type="file" placeholder="Select file...">Adjunte el soporte</mbsc-input>
        </div>

        <div class="alert alert-danger mt-3" role="alert" *ngIf="!(formDataUploadError?.upload && this.formDataUploadError?.validExtencion && this.formDataUploadError?.validSize)">

            <div class="mt-1 mb-1" *ngIf="!this.formDataUploadError?.upload">
                * El adjunto es obligatorio
            </div>

            <div class="mt-1 mb-1" *ngIf="this.formDataUploadError?.upload && !this.formDataUploadError?.validExtencion">
                * La extención del archivo debe ser png, jpg, jpeg
            </div>

            <div class="mt-1 mb-1" *ngIf="this.formDataUploadError?.upload && !this.formDataUploadError?.validSize">
                * El tamaño del archivo debe ser máximo de 3Mb
            </div>

        </div>

        <div *ngIf="this.formDataUploadError?.upload && this.formDataUploadError?.validExtencion && this.formDataUploadError?.validSize " class="mt-4 text-center text-md-start">
            <img [src]="preloadPng" alt="" class="img-preload">
        </div>

    </ng-container>

    <hr class="mt-5 mb-3">


    <div class="fw-bold mb-2 ">Tasa de cambio</div>

    <ng-container *ngIf="rate_load ">
        <div class="alert alert-secondary p-2" style="background-color: #f8d7da3b" role="alert">
            <span class="text-dark small">Consultando tasa (BCV)... </span>
        </div>
    </ng-container>


    <div class="text-secondary">
        1 USD = {{rate?.amount | currency:'USD':'': '1.2-2'}} BS

    </div>

    <div class="text-secondary">
        {{ amountFB | currency:'USD':'': '1.2-2'}} USD = {{ amountFB_bs | currency:'USD':'': '1.2-2'}} BS

    </div>

    <div class="d-grid gap-2 mt-4" *ngIf="!confirm">
        <button (click)="setNewOrder()" [disabled]="!(form?.getRawValue()?.total_amount > 0) || maxDuelbalance()  || (dataPost?.tpp?.code == 'PAGO_MOVIL' &&  !validationPhone) || (account?.bank?.name != 'Zelle' &&  !validationReference) || confirmLoading || (!(this.formDataUploadError?.upload && this.formDataUploadError?.validExtencion && this.formDataUploadError?.validSize)) || rate_error"
            class="btn btn-primary" type="button">Confirmar pago  <img *ngIf="confirmLoading" src="./assets/public/images/loading.gif " alt=" " style="width: 16px"></button>
    </div>


</ng-template>


<ng-template #view4 let-data>
    <!-- <pre>{{ responseTransaction | json }}</pre> -->


    <div class="text-start mt-4 mb-3" *ngIf="installment">

        <h3 class="text-dark fw-bold mt-2">
            <i *ngIf="!confirm" (click)="sectionTrans = 1" class="fas fa-angle-left pe-2"></i> Verificación del pago
        </h3>

    </div>

    <div class="alert alert-warning p-2" style="background-color: #f8d7da3b" role="alert" *ngIf="responseTransaction?.type == 'PENDING'">
        <span class="text-dark small"><span class="font-weight-bold">Su pago está siendo validado, por favor espere unos minutos.</span>
        </span>
    </div>
    

    <div class="text-secondary2 ">

        <ng-container>


            <div class="mb-3">

                <span class="badge text-bg-warning " *ngIf=" responseTransaction?.type == 'MANUAL' ">Pago por conciliar</span>
                <span class="badge text-bg-danger" *ngIf="responseTransaction?.type == 'AUTO'  &&  responseTransaction?.status == 'R'">Pago rechazado</span>
                <span class="badge text-bg-success" *ngIf="responseTransaction?.type == 'AUTO'  &&  responseTransaction?.status == 'A'">Pago aprobado</span>

                <div class="mb-3 text-dark fz-12 mt-2" *ngIf="responseTransaction?.descripcion">
                    {{responseTransaction?.descripcion}}
                </div>

            </div>



            <div class="mb-2 text-dark" *ngIf="dataPost?.bank">

                Cuenta origen

            </div>

            <div class="d-flex " *ngIf="dataPost?.bank">
                <div>
                    <div>
                        {{dataPost?.bank?.name}}
                    </div>
                </div>
            </div>

            <div class="mb-2 mt-4 text-dark">

                Cuenta destino

            </div>

            <div class="d-flex ">
                <div>
                    <div>
                        {{account?.bank?.name}}
                    </div>

                    <div class="fz-12">
                        {{account?.payment_method?.name}} /
                        <span class=" " *ngIf="account?.number">
                            {{account?.number}}
                        </span>

                        <span class="" *ngIf="account?.phone">
                            {{account?.phone}}
                        </span>

                    </div>

                </div>
                <div class=" ms-auto text-dark fw-bold">
                    {{form?.getRawValue()?.total_amount}} {{account?.currency?.name}}
                </div>
            </div>

            <div class="mb-2 mt-4 text-dark">

                Detalle

            </div>

            <div class="d-flex " *ngIf="form?.getRawValue()?.reference">
                <div>
                    Referencia
                </div>
                <div class="ms-auto">
                    #{{form?.getRawValue()?.reference}}
                </div>
            </div>

            <!-- <div class="d-flex mt-2">
                <div>
                    Identificación
                </div>
                <div class=" ms-auto">
                    V-19163767
                </div>
            </div> 
        -->

        </ng-container>


        <div class="d-flex mt-2" *ngIf="form?.getRawValue()?.description">
            <div>
                Descripción
            </div>
            <div class="ms-auto ">

                {{form?.getRawValue()?.description}}
            </div>
        </div>

        <div class="mb-3 mt-4 text-dark" *ngIf="responseTransaction?.voucher">
            Voucher
        </div>

        <div class="row" *ngIf="responseTransaction?.voucher">
            <div class="col-12 col-md-6">
                <div class="voucher p-2 text-dark text-uppercase" [innerHTML]="responseTransaction?.voucher">

                </div>

            </div>
        </div>

    </div>


    <div class="d-grid gap-2 mt-4" *ngIf="confirm || responseTransaction?.type == 'MANUAL' ">
        <button [routerLink]="['/payments']" class="btn btn-primary" type="button">Continuar</button>
    </div>

</ng-template>




<!-- <pre>{{ banks | json }}</pre> -->

<ng-template #tpp let-offcanvas>

    <div class="offcanvas-header">
        <div class="d-flex w-100 mt-2">

            <div class="text-start ">
                <div class="fw-bold fz-18">
                    Selecciona el tipo de pago

                </div>
            </div>

            <div class="text-end ms-auto">
                <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
            </div>

        </div>

    </div>

    <div class="offcanvas-body">

        <div class="card  cmt2 mt-4" (click)="setTpp({code:'TRANSFERENCIA',name:'Transferencia'})">
            <div class="card-body ">

                <div class="d-flex w-100 bd-highlight">
                    <div class="cupo-i  bd-highlight">
                        <i [ngClass]="{'light': (dataPost?.tpp?.code != 'TRANSFERENCIA'),'orange':(dataPost?.tpp?.code == 'TRANSFERENCIA')}" class="  far fa-check-circle  "></i>
                    </div>

                    <div class="flex-grow-1 bd-highlight pt-2">

                        <div class="d-flex w-100 ">
                            <div>
                                <span class="fw-bold ">Transferencia</span>

                            </div>
                            <div class="text-end ms-auto ">
                                <span class="text-dark fw-bold fz-18 "><i class="fas fa-chevron-right"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card  cmt2 mt-4" (click)="setTpp({code:'PAGO_MOVIL',name:'Pago Movil'})">
            <div class="card-body ">

                <div class="d-flex w-100 bd-highlight">
                    <div class="cupo-i  bd-highlight">
                        <i [ngClass]="{'light': (dataPost?.tpp?.code != 'PAGO_MOVIL'),'orange':(dataPost?.tpp?.code == 'PAGO_MOVIL')}" class="  far fa-check-circle  "></i>
                    </div>

                    <div class="flex-grow-1 bd-highlight pt-2">

                        <div class="d-flex w-100 ">
                            <div>
                                <span class="fw-bold ">Pago Movil</span>

                            </div>
                            <div class="text-end ms-auto ">
                                <span class="text-dark fw-bold fz-18 "><i class="fas fa-chevron-right"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card  cmt2 mt-4" (click)="setTpp({code:'DEPOSITO_BS',name:'Depósito en efectivo (Bs)'})">
            <div class="card-body ">

                <div class="d-flex w-100 bd-highlight">
                    <div class="cupo-i  bd-highlight">
                        <i [ngClass]="{'light': (dataPost?.tpp?.code != 'DEPOSITO_BS'),'orange':(dataPost?.tpp?.code == 'DEPOSITO_BS')}" class="  far fa-check-circle  "></i>
                    </div>

                    <div class="flex-grow-1 bd-highlight pt-2">

                        <div class="d-flex w-100 ">
                            <div>
                                <span class="fw-bold ">Depósito en efectivo (Bs)</span>

                            </div>
                            <div class="text-end ms-auto ">
                                <span class="text-dark fw-bold fz-18 "><i class="fas fa-chevron-right"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card  cmt2 mt-4" (click)="setTpp({code:'DEPOSITO_USD',name:'Depósito en efectivo (USD)'})">
            <div class="card-body ">

                <div class="d-flex w-100 bd-highlight">
                    <div class="cupo-i  bd-highlight">
                        <i [ngClass]="{'light': (dataPost?.tpp?.code != 'DEPOSITO_USD'),'orange':(dataPost?.tpp?.code == 'DEPOSITO_USD')}" class="  far fa-check-circle  "></i>
                    </div>

                    <div class="flex-grow-1 bd-highlight pt-2">

                        <div class="d-flex w-100 ">
                            <div>
                                <span class="fw-bold ">Depósito en efectivo (USD)</span>

                            </div>
                            <div class="text-end ms-auto ">
                                <span class="text-dark fw-bold fz-18 "><i class="fas fa-chevron-right"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- <div class="card  cmt2 mt-4" (click)="setTpp({code:'zelle',name:'zelle'})">
            <div class="card-body ">

                <div class="d-flex w-100 bd-highlight">
                    <div class="cupo-i  bd-highlight">
                        <i [ngClass]="{'light': (dataPost?.tpp?.code != 'zelle'),'orange':(dataPost?.tpp?.code == 'zelle')}" class="  far fa-check-circle  "></i>
                    </div>

                    <div class="flex-grow-1 bd-highlight pt-2">

                        <div class="d-flex w-100 ">
                            <div>
                                <span class="fw-bold ">zelle</span>

                            </div>
                            <div class="text-end ms-auto ">
                                <span class="text-dark fw-bold fz-18 "><i class="fas fa-chevron-right"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->


    </div>


</ng-template>


<ng-template #bank let-offcanvas>

    <div class="offcanvas-header">
        <div class="d-flex w-100 mt-2">

            <div class="text-start ">
                <div class="fw-bold fz-18">
                    Selecciona el banco origen

                </div>
            </div>

            <div class="text-end ms-auto">
                <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
            </div>

        </div>

    </div>

    <div class="offcanvas-body">

        <div class="card  cmt2 mt-4" (click)="setBank({code:item.code,name:item.name})" *ngFor="let item of banks">
            <div class="card-body ">

                <div class="d-flex w-100 bd-highlight">
                    <div class="cupo-i  bd-highlight">
                        <i [ngClass]="{'light': (dataPost?.bank?.code != item?.code),'orange':(dataPost?.bank?.code == item?.code)}" class="  far fa-check-circle  "></i>
                    </div>

                    <div class="flex-grow-1 bd-highlight pt-2">

                        <div class="d-flex w-100 ">
                            <div>
                                <span class="fw-bold ">{{item.name}} </span>
                                <span *ngIf="item.bankCode" class="small ps-1">({{item.bankCode}})</span>


                            </div>
                            <div class="text-end ms-auto ">
                                <span class="text-dark fw-bold fz-18 "><i class="fas fa-chevron-right"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>


</ng-template>







<div class="container mt-2  mt-md-4">

    <ng-container *ngIf="!loading && sectionTrans == 1">
        <div class="text-start mt-4 " *ngIf="installment">


            <h3 class="text-dark fw-bold mt-2">
                Reportar pago
            </h3>
            <small>{{installment?.invoice?.identifier}} </small> (
            <small class="text-secondary ">
                   
                    {{ installment?.order
                        < 1 ? 'Inicial': 'Cuota '+ installment?.order}}       
                </small>)
        </div>


        <div>

            <h5 class="text-dark fw-bold mt-5  mb-2">
                Origen del pago
            </h5>
        </div>


        <div class="card  cmt2 mt-4" (click)="openview(tpp)">
            <div class="card-body ">


                <div class="d-flex w-100 bd-highlight">

                    <div class="cupo-i  bd-highlight">
                        <i [ngClass]="{'light': !dataPost.tpp,'orange': dataPost.tpp}" class=" far fa-check-circle  "></i>
                    </div>

                    <div class="flex-grow-1 bd-highlight">

                        <div class="d-flex w-100 ">
                            <div>
                                <span class="fw-bold ">Selecciona el tipo de pago</span>

                            </div>
                            <div class="text-end ms-auto ">
                                <span class="text-dark fw-bold fz-18 "><i class="fas fa-chevron-right"></i></span>
                            </div>
                        </div>

                        <div>
                            <span *ngIf="!dataPost.tpp" class=" text-secondary fz-12 ">
                                Pago móvil, Transferencia, Entre otros
                            </span>

                            <span *ngIf="dataPost.tpp" class="  small ">
                               {{dataPost.tpp?.name}}
                            </span>

                        </div>


                    </div>

                </div>


            </div>

        </div>



        <div class="card  cmt2 mt-4" (click)="openview(bank)" *ngIf="dataPost?.tpp?.code != 'DEPOSITO_USD' && dataPost?.tpp?.code != 'DEPOSITO_BS'">
            <div class="card-body ">
                <div class="d-flex w-100 bd-highlight">
                    <div class="cupo-i  bd-highlight">
                        <i [ngClass]="{'light': !dataPost.bank,'orange': dataPost.bank}" class=" far fa-check-circle  "></i>
                    </div>
                    <div class="flex-grow-1 bd-highlight">

                        <div class="d-flex w-100 ">
                            <div>
                                <span class="fw-bold ">Selecciona el banco origen</span>

                            </div>
                            <div class="text-end ms-auto ">
                                <span class="text-dark fw-bold fz-18 "><i class="fas fa-chevron-right"></i></span>
                            </div>
                        </div>

                        <div>

                            <span *ngIf="!dataPost.bank" class=" text-secondary fz-12 ">
                                Banesco, Mercantil, entre otros
                            </span>

                            <span *ngIf="dataPost.bank" class="  small ">
                               {{dataPost.bank?.name}}
                            </span>


                        </div>


                    </div>

                </div>


            </div>

        </div>

        <div class="text-start mt-4 " *ngIf="dataPost.tpp && (dataPost.bank || (dataPost?.tpp?.code == 'DEPOSITO_USD' || dataPost?.tpp?.code == 'DEPOSITO_BS'))">


            <h5 class="text-dark fw-bold mt-5  mb-3">
                Detalles del pago
            </h5>
            <div class="d-flex">
                <div>
                    <div class="fw-bold">Monto del pago</div>

                </div>
                <div class="ms-auto text-end ">
                    <div class="fw-bold fz-18"> {{installment.dueBalance_init | currency:'USD':'': '1.2-2'}} USD</div>

                    <div *ngIf="dataPost?.bank?.name == 'Zelle' || dataPost?.tpp?.code == 'DEPOSITO_USD'" class="fz-12 text-secondary">IGTF incluido</div>
                    <div class="text-danger" *ngIf=" remanente?.payment_status_remante"> +{{remanente?.remaining_amount}} USD</div>

                </div>

            </div>


            <div class="card  cmt2 mt-4">
                <div class="card-body ">

                    <div class="d-flex w-100 ">
                        <div>
                            <span class="fw-bold "> {{account?.bank?.name}}</span>

                        </div>
                        <div class="text-end ms-auto ">
                            <span class="text-dark ">
                                <button (click)="openview(optonsAcciones)"  class="btn btn-primary btn-sm" type="button">Cambiar</button>
                            </span>
                        </div>
                    </div>



                    <div>

                        <span *ngIf="account?.payment_method" class="  small ">
                            {{account?.payment_method?.name}}
                       </span>


                    </div>


                </div>

            </div>

            <div class="card  cmt2 mt-4" *ngIf="account?.number" (click)="copiar(account?.number,'number')">
                <div class="card-body ">

                    <div class="d-flex w-100 ">
                        <div>
                            <span class="fw-bold ">Número de cuenta</span>

                        </div>
                        <div class="text-end ms-auto ">
                            <span class="text-dark fw-bold text-primary ">Copiar</span>
                        </div>
                    </div>


                    <div>

                        <span class="  small ">
                            {{account?.number}} 
                       </span>


                    </div>


                </div>

            </div>

            <div class="card  cmt2 mt-4" *ngIf="account?.phone" (click)="copiar(account?.phone,'phone')">
                <div class="card-body ">

                    <div class="d-flex w-100 ">
                        <div>
                            <span class="fw-bold ">Teléfono</span>

                        </div>
                        <div class="text-end ms-auto ">
                            <span class="text-dark fw-bold text-primary ">Copiar</span>
                        </div>
                    </div>


                    <div>

                        <span class="  small ">
                            {{account?.phone}}
                       </span>


                    </div>


                </div>

            </div>

            <div class="card  cmt2 mt-4" *ngIf="account?.email" (click)="copiar(account?.email)">
                <div class="card-body ">

                    <div class="d-flex w-100 ">
                        <div>
                            <span class="fw-bold ">Email</span>

                        </div>
                        <div class="text-end ms-auto ">
                            <span class="text-dark fw-bold text-primary ">Copiar</span>
                        </div>
                    </div>


                    <div>

                        <span class="  small ">
                            {{account?.email}} 
                       </span>


                    </div>


                </div>

            </div>


            <div class="card  cmt2 mt-4" *ngIf="account?.taxId" (click)="copiar(account?.taxId,'taxId')">
                <div class="card-body ">

                    <div class="d-flex w-100 ">
                        <div>
                            <span class="fw-bold ">Rif</span>

                        </div>
                        <div class="text-end ms-auto ">
                            <span class="text-dark fw-bold text-primary ">Copiar</span>
                        </div>
                    </div>

                    <div>
                        <span class="  small ">
                            {{account?.taxId}} 
                       </span>
                    </div>
                </div>

            </div>


            <div class="card  cmt2 mt-4" (click)="copiar(account?.currency?.code == 'BS'? amountFB_bs : amountFB)">
                <div class="card-body ">

                    <div class="d-flex w-100 ">
                        <div>
                            <span class="fw-bold ">Monto</span>

                        </div>
                        <div class="text-end ms-auto ">
                            <span class="text-dark fw-bold text-primary ">Copiar</span>
                        </div>
                    </div>


                    <div>

                        <span class="  small ">
                            <span *ngIf="account?.currency?.code == 'BS'">{{  amountFB_bs | currency:'USD':'': '1.2-2'}} BS</span>
                        <span *ngIf="account?.currency?.code != 'BS'">{{  amountFB | currency:'USD':'': '1.2-2'}} USD</span>

                        </span>


                    </div>


                </div>

            </div>

            <hr class="mt-5 mb-3">


            <div class="fw-bold mb-2 ">Tasa de cambio</div>

            <ng-container *ngIf="rate_load ">
                <div class="alert alert-secondary p-2" style="background-color: #f8d7da3b" role="alert">
                    <span class="text-dark small">Consultando tasa (BCV)... </span>
                </div>
            </ng-container>


            <div class="text-secondary">
                1 USD = {{rate?.amount | currency:'USD':'': '1.2-2'}} BS

            </div>

            <div class="text-secondary">
                {{ amountFB | currency:'USD':'': '1.2-2'}} USD = {{ amountFB_bs | currency:'USD':'': '1.2-2'}} BS

            </div>

            <div class="d-grid gap-2 mt-4">
                <button (click)="sectionTrans = 2;resetForm()" class="btn btn-primary" type="button">Cargar pago</button>
            </div>



        </div>
    </ng-container>


    <ng-container *ngIf="!loading && sectionTrans == 2">


        <div class="text-start mt-4 " *ngIf="dataPost.tpp ">
            <ng-container class="text-capitalize " *ngTemplateOutlet="view1 "></ng-container>

        </div>
    </ng-container>

    <ng-container *ngIf="!loading && sectionTrans == 3">
        <div class="text-start mt-4 " *ngIf="dataPost.tpp ">
            <ng-container class="text-capitalize " *ngTemplateOutlet="view4 "></ng-container>

        </div>
    </ng-container>


    <!-- <pre>{{ dataPost | json }}</pre> -->




    <ng-container *ngIf="loading ">
        <div class="w-100 text-center  ">
            <img src="./assets/public/images/loading.gif " alt=" ">
        </div>
    </ng-container>
</div>






<!-- <pre>{{ accountPayment | json }}</pre> -->