import { MbscModule } from '@mobiscroll/angular';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SocketioService } from './services/socket/socketio.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { NavbarauthComponent  } from './components/navbarauth/navbarauth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/auth/login-form/login.component';
import { AuthService } from './services/auth/auth.service';
import { AuthInterceptorService } from './interceptor/auth-interceptor.service';
import { LogoutComponent } from './components/auth/logout/logout.component';
import {  NoAuthGuard } from './guards/NoAuth.guard';
import { JwtModule } from "@auth0/angular-jwt";
import { RegistroComponent } from './components/auth/registro/registro.component';
import { UserService } from './services/user/user.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { IdentGuard } from './guards/ident.guard';
import { IsAuthGuard } from './guards/isAuth.guard';
import { CommonModule } from '@angular/common';
import { CreditOptionComponent } from './components/credit-option/credit-option.component';
import { CreditCalculatorComponent } from './components/credit-calculator/credit-calculator.component';
import { ValidatePhoneComponent } from './components/validate-phone/validate-phone.component';
import { ContactFinancialPaymentsComponent } from './components/contact-financial-payments/contact-financial-payments.component';
import { PostComponent } from './components/post/post.component';
import { WebcamModule } from 'ngx-webcam';
import { InstallmentsConsoleComponent } from './components/installments-console/installments-console.component';
import { NgToggleModule } from 'ng-toggle-button';
import { InstallmentsComponent } from './components/installments/installments.component';
import { ApproveConditinsViewComponent } from './components/approve-conditins-view/approve-conditins-view.component';
import { ContactOnboardingComponent } from './components/contact-onboarding/contact-onboarding.component';
import { ApproveConditionsComponent } from './components/approve-conditions/approve-conditions.component';
import { InvoiceDetailComponent } from './components/invoice-detail/invoice-detail.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ApproveConditionsAcountComponent } from './components/approve-conditions-account/approve-conditions-account.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarauthComponent,
    LoginComponent,
    LogoutComponent,
    RegistroComponent,
    DashboardComponent,
    CreditOptionComponent,
    CreditCalculatorComponent,
    ValidatePhoneComponent,
    ContactFinancialPaymentsComponent,
    PostComponent,
    InstallmentsConsoleComponent,
    InstallmentsComponent,
    ApproveConditinsViewComponent,
    ContactOnboardingComponent,
    ApproveConditionsComponent,
    InvoiceDetailComponent,
    InvoicesComponent,
    PaymentsComponent,
    ApproveConditionsAcountComponent
  ],

  imports: [ 
    MbscModule,  
    JwtModule.forRoot({
      config: {},
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy:'registerImmediately' 
    }),
    NgbModule,
    CommonModule,
    WebcamModule,
    NgToggleModule.forRoot(), //or NgToggleModule
    CurrencyMaskModule

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    SocketioService,
    AuthService,
    UserService,
    IdentGuard,
    IsAuthGuard,
    NoAuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
