import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Clipboard } from '@angular/cdk/clipboard';

import { mobiscroll, MbscDatetimeOptions, MbscFormOptions } from '@mobiscroll/angular';
import { CurrencyPipe, DatePipe, DecimalPipe, formatCurrency, getCurrencySymbol } from '@angular/common';
import * as alertifyjs from 'alertifyjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { async } from '@angular/core/testing';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { debug } from 'console';
import { LocationServiceService } from '../../services/location-service.service';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light'
};

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css'],
  providers: [DecimalPipe,CurrencyPipe,DatePipe],

})

export class PostComponent implements OnInit {
  responseTransaction:any = {};

  reference_length = 8;
  reference_length_max = 8;

   dataPost :any = {};
   accountPaymentDF :any = {};
   validationReference:any = false;
   validationPhone:any = false;

   preloadPng:any;
   validationAccountNumber:any = false;

  formData: any = {
    formaPago: "",
    cuenta: "",
    pais: "",
    monto: this.updateValueCurrency('0'),
    descripcion: "",
    upload:null,

  };
  errorMessage = 'Error message!;'
   
  type: any;

  confirm = false;
  confirmLoading = false;
  ordernumber:any;
  banks:any = [];

  selectView: any = 1;
  selectMaxView: any = 2;

  formDataUpload:any = null;
  formDataUploadError:any = {upload:false};


  fileName = '';
  @Input() internal: any;

  queryParams: any;
  loading: any = true;
  person: any;

  form:any;
  installment:any;



  amountF:any = 0;
  amountFB:any = 0;
  amountFB_bs:any = 0;

  totalpaymentReference = 0;

  payments = [];
  paymentsReference = [];
  accountPayment:any;
  accountPayment_load:any;
  account:any;
  account_payment:any;
  rate:any;
  regx_total_amount = "^(([0-9]*)|(([0-9]*)\.([0-9]*)))$"

  date_base: any = new Date;
  date_base_string :any;
  deductions_payment : any = {};

  sectionTrans = 1;


  mobileSettingsFile: MbscDatetimeOptions = {
    display: 'bottom'
};


mobileSettings: MbscDatetimeOptions = {
  display: 'bottom',
  themeVariant: 'dark',
};

  desktopSettings: MbscDatetimeOptions = {
      display: 'bubble',
      touchUi: false
  };

  formSettings: MbscFormOptions = {
      inputStyle: 'box'
  };

  rate_load:any;
  rate_error:any;
  deduction_payment:any;
  dueBalance:any = 0;
  dueBalanceCurrecy:any = 0;

  remanente:any;
  latitude = 0;
  longitude = 0;

  constructor(
    private offcanvasService: NgbOffcanvas,
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    private router: Router,
    private route: ActivatedRoute,
    public _auth : AuthService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    public socketService: SocketioService,
    private datePipe: DatePipe,
    public clipboard: Clipboard,
    private locationService: LocationServiceService

  ) { 

  }

  ngOnInit(): void {
    this.getLocation();

    this.date_base_string = this.datePipe.transform(this.date_base, 'MM/dd/yyyy ');

    this.getBanks();
    this.getAccountPayment();
    this.getDeduction_payment();

    this.activatedRoute.params.subscribe(params => {
      this.queryParams = params;

      if (this.queryParams?.invoice && this.queryParams?.installment) {

        this.getRate(null,true);

      }else {

        console.log(this.queryParams);
        
       //   this.router.navigate(['/'], {});

      }

    });


    this.form = this.formBuilder.group({
      description: [''],
      reference: ['',[Validators.required, Validators.pattern("^[0-9]*$")]],
      total_amount: ['',[Validators.required,Validators.pattern(this.regx_total_amount)]],
      code_identity: ['V'],
      identity: [''],
      code_phone: ['0414'],
      phone: [''],
      accountNumber:[''],
      
      

  });

  this.validateReferenceLength();


    const type = this.route.snapshot.paramMap.get('type') || "deposit" ;

    switch (type) {

      case 'deposit':
        this.type = 'depósito';
        break;

      case 'withdrawal':
        this.type = 'retiro';
        break;

    }

    /*if (this.type !== 'depósito' && this.type !== 'retiro') {
      this.router.navigate(['/console/order/dashboard'], {});
    }*/

  }

  validateNumericInput(event: KeyboardEvent): void {
    const keyCode = event.keyCode;
    if ((keyCode < 48 || keyCode > 57) && keyCode!= 46) {
      event.preventDefault();
    }
  }
  
  validateReferenceLength(): void {
  
    const referenceControl = this.form.get('reference');
      referenceControl.clearValidators();
  
    referenceControl.setValidators([
      Validators.minLength(this.reference_length),
      Validators.maxLength(this.reference_length_max)
    ]);
  
    referenceControl.updateValueAndValidity();
  }

  getLocation() {
    this.locationService.getPosition().then(pos => {
      this.latitude = pos.lat;
      this.longitude = pos.lng;
      console.log(`Latitud: ${this.latitude}, Longitud: ${this.longitude}`);
    }).catch(err => {
      console.error('Error obteniendo la ubicación:', err);
    });
  }

  setTpp(tpp:any){
    
    this.dataPost.tpp = tpp;

    if(this.dataPost?.bank?.name == 'Zelle' && tpp?.code == 'PAGO_MOVIL'){

      this.dataPost.bank = null;

    }
    this.processBase()

    this.offcanvasService.dismiss('Cross click');

  }


  setBank(bank:any){
    this.dataPost.bank = bank;

    if(bank.name == 'Zelle'){
      this.setTpp({code:'TRANSFERENCIA',name:'Transferencia'});
    }else{
      this.processBase();
      this.offcanvasService.dismiss('Cross click');

    }

  }


  getDeduction_payment = async () => {

    try {
      const resp = await this._user.getDeduction_payment({});
      this.deduction_payment = resp.res;
    } catch (error) {
      console.log(error);
      
    }
       
  }
    

  getPerson = async (code:any) => {
    const resp = await this._user.getPersonbyCode({ code });
    this.person = resp.resp;
  }


  birthdaySet(e: any) {

    if (e) {
      e.blur();
    }
  }

  selectInst(opt: any) {
    this.formData.instrumento = opt;
    this.offcanvasService.dismiss('Cross click');
    this.formData.monto = this.formData.instrumento.monto || this.updateValueCurrency('0');

  }

  onSet() {

    let s2 = this.formData?.instrumento?.monto;
    let s3 = s2.replace(",", "");

    const x = (this.formData.cantidad * parseFloat(s3)).toFixed(2);

    this.formData.monto = this.decimalPipe.transform(x, '1.2-2');
  }


  

  nextStep() {

    if (this.selectView < this.selectMaxView) {

      
      if ( this.form.valid && this.account ) {
        this.selectView = this.selectView + 1;
             
      }else{
        this.form.pristine = false;
      }

    } else if (this.confirm) {

      this.redirectTo();

      this.form.submit();

    } else {

      if ( this.form.valid && this.account ) {
        this.setNewOrder();
             
      }

    }

  }

  redirectTo() {
    this.router.navigate([`/redirectTo`], {});
  }

  backStep() {

    if (this.selectView > 1) {

      this.selectView = this.selectView - 1;

    }
  }

  detectBrowser() {
    var userAgent = navigator.userAgent;
    if (userAgent.indexOf("Chrome") > -1) {
        return "Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
        return "Safari";
    } else if (userAgent.indexOf("Firefox") > -1) {
        return "Firefox";
    } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
        return "Internet Explorer";
    } else if (userAgent.indexOf("Edge") > -1) {
        return "Edge";
    } else {
        return "Unknown";
    }
}


  getDeviceInfo() {
    const userAgent = this.detectBrowser();
    const platform = navigator.platform;
    const version = navigator.appVersion;

    let platform_text = platform.split(" ");
  
    return {
      userAgent,
      platform: platform_text[0],
      version
    };
  }

  
  equal(a: number, b: number) {
    const m = a == this.selectMaxView;
    return m;
  }


  setNewOrder = async () => {
    let info = this.getDeviceInfo();
    let client_identify: any = {
      ipaddress: "",
      browser_agent: info?.userAgent ?? "",
      mobile: {
        manufacturer: "",
        model: "",
        os_version: info?.platform ?? "",
        location: {
          lat: this.latitude,
          lng: this.longitude,
        },
      },
    };

    if ( this.form.valid) {

      this.confirmLoading = true;

      try {



        const form = this.form.getRawValue();
      
        let {
          description,
          payment_method,
          reference,
          code_identity,
          identity,
          total_amount,
          phone,
          origin_account,
          code_phone,
          accountNumber
        }  = form;

        const formData = new FormData();

        if (this.formDataUpload) {

          formData.append("archivo", this.formDataUpload);
        
      }
      

      let code = this.person?.person?.code;
      
      let deductions_payment:any = {};
    
      let account = this.account;

      this.deduction_payment.forEach((v:any, key:any) => {
    

        if((v?.currency?.code == account?.currency?.code) || !v?.currency ){
          if(v.isPorcent){
            let resp = v?.amount || 0;
          
            let porcent = this.toFixedApp(this.toFixedApp((total_amount * resp) / 100));
    
              deductions_payment[v._id] = porcent;
          }
        }
    
        });
    
      
        origin_account = '';

      let bank = this.banks?.filter((item:any) => item.code == this.dataPost?.bank?.code);

      if(bank[0]?.bankCode){

        origin_account = bank[0]?.bankCode;

      }

      formData.append("params", JSON.stringify({
        currency:this.account?.currency?.code,
        description,
        payment_method,
        reference,
        cid:`${code_identity}${identity}`,
        total_amount,
        code_person: code,
        code_account_payment : this.account_payment,
        code_installment:this.installment?.code,
        date_register:this.date_base_string,
        deductions_payment: deductions_payment,
        phone:`${code_phone}${phone}`,
        bankCode:origin_account,
        accountNumber,
        client_identify


      }));

      console.log(formData);

        let resp = await this._user.getRegister_payment(formData);

        this.responseTransaction = resp;
        if(this.responseTransaction?.status == 'A'){
          this.confirm = true;
        }
        
    
        //  this.ordernumber = resp?.res?.code;
  
          this.confirm = true;
  
          this.confirmLoading = false

          this.sectionTrans = 3;

          this.confirmLoading = false;

  
      } catch (error:any) {

        if(error.status == 400 && error?.error?.control){


          this.responseTransaction = error?.error;
          this.sectionTrans = 3;


        }else{
      
          if(error?.error?.code == 52){
            
            alertifyjs.error(error?.error?.msg);

          }else{

            alertifyjs.error(`Error interno, vuelva a cargar el pago`);

          }


        // alertifyjs.error(`Error al crear solicitud `);

        }

        this.confirmLoading = false;

      }
  
    }

  }

  updateValueCurrency(value: any) {

    return this.currencyPipe.transform(value, 'USD', '', '1.2-2');
}

 validarExtension(archivo:any) {
  // Obtener la extensión del archivo
  let extension = archivo.name.split('.').pop().toLowerCase();

  // Verificar si la extensión es PNG, JPG o JPEG
  if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
      return true;
  } else {
      return false;
  }
}

validarTamañoArchivo(archivo:any) {
  let tamañoMax = (1024 * 3) * 1024; // Tamaño máximo en bytes (en este caso, 1MB)

  if (archivo.size > tamañoMax) {
    return false;
  } else {
    return true;
  }
}

onFileSelected  = async  (event:any) => {

  const file:File = event.target.files[0];



  
  if (file) {
    this.formDataUploadError.upload = true;



    let validExtencion = this.validarExtension(file); 
    

    if(!validExtencion){
      this.formDataUploadError.validExtencion = false;

    }else{
      this.formDataUploadError.validExtencion = true;

    }

    let validSize = this.validarTamañoArchivo(file); 

    if(!validSize){
      this.formDataUploadError.validSize = false;

    }else{
      this.formDataUploadError.validSize = true;

    }
  


    const reader = new FileReader();
    reader.onload =(event:any)=>{
      this.preloadPng = event.target.result;
  
    }
  
    reader.readAsDataURL(file);

      this.fileName = file.name;
      this.formDataUpload = file;

     /* const formData = new FormData();

      formData.append("archivo", file);
      
      formData.append("params", JSON.stringify({
         nombre:"juan soto"
      })

      
    );*/
     // const resp = await this._LaService.setUpload(formData);

  }
}



getIdentity(item: any) {

  if (!(item?.identitys?.length > 0))
    return "";

  return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

}


getContact(item: any) {

  if (!(item?.constacts?.length > 0))
    return "";

  return `${item?.constacts[0]?.contact}`;

}


sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}


getPersonInstallmentsInvoiceCredits = async (invoice:any) => {
  this.loading = true;
  try {

       const data =  {
        code_invoice:invoice
       
       }



     const  installments  = await this._user.getPersonInstallmentsInvoiceCredits(data);

     installments?.res?.map((value:any, index:any) => {

      if(value?.installMent?.code == this.queryParams?.installment){
   
        this.installment = value?.installMent;
        this.payments = value?.payments;
        this.paymentsReference = value?.paymentReference;
        this.dueBalance =  this.toFixedApp(value?.installMent?.invoice?.dueBalance);
        this.dueBalanceCurrecy = this.dueBalance;

      }

      return value;
    });

    

    
    if(!this.installment){
      this.router.navigate(['/'], {});

    }



  let remanente_resp =  await this._user.getRemanentInvoice({
    "invoiceCode": invoice,
    "status": true
   })


   let remanente: any;
   remanente_resp?.map((value2:any)=>{

     remanente = {};

      remanente['payment_status_remante'] = true;
      remanente['remaining_amount'] =  value2?.remaining_amount;
      remanente['total_amount_next_installment'] =  value2?.total_amount_next_installment;
    });

  this.remanente = remanente;


  if(remanente){
    this.installment.dueBalance_init =  this.installment.dueBalance;

    this.installment.dueBalance = remanente.total_amount_next_installment;


  }

    this.getPerson(this.installment?.invoice?.customer?.code);

    this.paymentsReference?.map((value:any, index:any) => {

      this.totalpaymentReference = this.totalpaymentReference + value?.payment?.amount;
      return value;
      
    });


    this.amountFB =  this.toFixedApp((this.installment?.dueBalance - this.totalpaymentReference)) ;
    this.amountFB_bs  = this.toFixedApp(this.amountFB * this.rate.amount);
    this.amountF =  0 ;

    this.loading = false;

    } catch (error) {

      this.loading = false;

      console.error(error);
     // this.router.navigate(['/'], {});

    }

    this.loading = false;

}

changeMount(){
  let value = this.toFixedApp(this.form?.getRawValue()?.total_amount) ;

  this.setAmount();

  let f = this.amountFB;

  let account = this.account;

  this.dueBalanceCurrecy = this.toFixedApp(this.toFixedApp(this.dueBalance) +  this.toFixedApp(10));

  if(account?.currency?.code == "BS"){
    this.dueBalanceCurrecy = this.toFixedApp(this.toFixedApp(this.toFixedApp(this.dueBalance) +  this.toFixedApp(10)) * this.toFixedApp(this.rate.amount)); 


      f  = this.toFixedApp(this.amountFB * this.rate.amount);
      this.amountFB_bs  = this.toFixedApp(this.amountFB * this.rate.amount);

  }



  this.amountF =   this.toFixedApp(f - (value || 0)) ;
  
/*
  this.dueBalance = (this.installment.dueBalance - value) < 0? 0 :  (this.installment.dueBalance - value) ;
  this.amount = (this.installment.amount + value) > this.installment.amount ? this.installment.amount :  (this.installment.amount + value) ;
  
*/

}

rateConverte(){
  let value = this.form?.getRawValue()?.total_amount;

  if(this.account?.currency?.code == "BS"){
    value = value / this.rate?.amount;

  }

  return value;

}



openview(content: TemplateRef<any>) {

  this.offcanvasService.open(content, { position: 'start', keyboard: false });

}

getAccountPayment = async () =>  {


     this.accountPayment_load = true;

  try {
    

    let resp =  await this._user.account_payment();

    this.accountPayment = resp?.res;
    this.accountPayment_load = false;

    const  accountPaymentDF  = await this._user.getAccountDefault();

    this.accountPaymentDF = accountPaymentDF;


   /* this.accountPaymentDF = {
      
      'TRANSFERENCIA' : '47f2069c-310d-40fc-9cda-82ca10dcf981',
      'PAGO_MOVIL' : '47f2069c-310d-40fc-9cda-82ca10dcf981',
      'TRANSFERENCIA_zelle' : 'b7064076-3c31-4e80-bf1e-182b6465509e'
      
    }*/

  
  } catch (error) {
    console.log(error);
    this.accountPayment_load = false;

    
  }

}


selectAccount(account:any = null){
  this.account_payment = account?.code || null;
  this.account = account || null;
  // if(this.account?.reference_length) {
  //   this.reference_length = this.account?.reference_length;
  // } else {
  //   this.reference_length = 6;
  // }
  this.form.get('total_amount').setValue('');
  this.form.get('total_amount').patchValue('');
  this.changeMount();
  this.offcanvasService.dismiss();
  
}

getRate = async (date= null,init=false,changeMount =false) => {
  try {
    this.rate_error = false;

    this.rate_load = true;
    
    let data:any =  { "subscriptor":1};

    if(date){
          data = { date,subscriptor:1}
      }

      try {
        const  respose  = await this._user.getRate(data);

        if(!respose)
        this.rate_error = true;

        let rate = respose?.res;
        
        this.rate = rate;
        if(changeMount){
          this.changeMount();

        }       
         this.rate_load = false;

        if(init){
          this.getPersonInstallmentsInvoiceCredits(this.queryParams?.invoice);

        }

      } catch (error) {
        console.log(error);
        this.rate_error = true;
        this.rate_load = false;

      }

 
  } catch (error) {
    console.log(error);
  }
  
};

toFixedApp = (x:any) => {

  let a:any = `e+${2}`;
  let b:any = `e-${2}`;

  return +(Math.round(x + a) + b);

  //return Number(Number.parseFloat(String((x + Number.EPSILON))).toFixed(TOFIXED));
  
}


pec = () => {
  return (this.amountF) < 0;
}

pexe = () => {
  let result =  this.amountF  ;
  return result;
}



dateSet(e: any) {

  let date_base =  e.valueText;
  
  let  temp = date_base? date_base.split("/") : [];
  this.date_base_string = `${temp[1]}/${temp[0]}/${temp[2]}`;
  

  this.getRate(this.date_base_string,false,true);



}

setAmount() {
  let amountFB =  this.toFixedApp((this.installment?.dueBalance - this.totalpaymentReference)) ;

  let account = this.account;
  this.deduction_payment.forEach((v:any, key:any) => {


    if((v?.currency?.code == account?.currency?.code) || !v?.currency ){
      if(v.isPorcent){
        let resp = v?.amount || 0;
       

         let porcent = this.toFixedApp(this.toFixedApp((amountFB * resp) / 100));
 
          amountFB = this.toFixedApp(amountFB + porcent);
      }
    }


});


this.amountFB = amountFB;
  
}



gotoWhatsapp(num:any) : void {
  window.open(" https://wa.me/"+num?.replace('+', ''), "_blank");
}


getBanks = async () => {
  try {

    const resp = await this.getBanksServer();
    this.banks = resp?.res;

    let shops_resp = resp?.res;
    let shops:any = {};

    
  } catch (error) {
    console.error(error);

  } 

 


}


getBanksServer = async () => {
  try{
      return this._user.getBanks({});

    } catch (error) {
      console.error(error);
      
      
    }
}

processBase(){

  debugger

  if(this.dataPost.tpp ){

    let accountPayment:any ;
    let accountPaymentAux:any ;

    this.accountPayment.map((item:any) =>{

      if(this.dataPost?.tpp?.code == 'DEPOSITO_USD' || this.dataPost?.tpp?.code == 'DEPOSITO_BS'){

        if(this.dataPost?.tpp?.code  ==   item?.payment_method?.code && this.accountPaymentDF[this.dataPost.tpp.code]  ==  item?.bank?.code ){
  
          accountPaymentAux = item;
  
        }

      }else{
        
/*
        if(item?.bank?.code == this.dataPost?.bank?.code &&  item?.payment_method?.code == this.dataPost?.tpp?.code ){
          accountPayment = item;
          
        }else*/
        
        let code = this.dataPost.tpp.code;

        if(this.dataPost?.bank?.name == 'Zelle'){
          code = 'TRANSFERENCIA_zelle';
        }

        
        
        if(this.dataPost?.tpp?.code  ==   item?.payment_method?.code && this.accountPaymentDF[code]  ==  item?.bank?.code ){
  
          accountPaymentAux = item;
  
        }
       
        
      }
     
     




    });


    if (accountPayment) {

      this.selectAccount(accountPayment)


    }else if (accountPaymentAux){

      this.selectAccount(accountPaymentAux)

    }else{

       this.selectAccount(accountPaymentAux)

    }

  

  }

  //this.selectAccount(item)
}



copiar(data:any,type:any = false){
  
 let text = data;

 if(type == 'number'){

  text = text.replace(/-/g, '');

 }else if(type == 'phone'){

  text = text.replace("+58", "0");


 }else if(type == 'taxId'){

  text = text.replace(/-/g, '').slice(1);


 }



  this.clipboard.copy(text);
}


validatePhone = () =>{


  let campo = this.form.controls.phone.value;
   if(this.dataPost?.tpp?.code == 'PAGO_MOVIL'){
   

     if(campo &&  /^\d+$/.test(campo) && campo.length > 6){


       let valueCampo = campo.substr(0, 7);

       this.form.get('phone').setValue(valueCampo);


     this.validationPhone = true;

   }else{

     this.validationPhone = false;
     

   }
 }



}
validateNumberReference = ( min: any, max: any) =>{
  let campo = this.form.controls.reference.value;
  if(campo.length >= min && campo.length <= max){
    this.validationReference = true;
    // } else if(campo &&  /^\d+$/.test(campo) && campo.length > max){
    //   let valueCampo = campo.substr(0, max);
    //   this.form.get('reference').setValue(valueCampo);
    //   this.validationReference = true;
  } else {
    this.validationReference = false;
  }
    
}

resetForm(){

  this.form = this.formBuilder.group({
    description: [''],
    reference: ['',[Validators.required]],
    total_amount: ['',[Validators.required,Validators.pattern(this.regx_total_amount)]],
    code_identity: ['V'],
    identity: [''],
    code_phone: ['0414'],
    phone: [''],
    accountNumber: ['']


});
}


maxDuelbalance(){
  return this.form.getRawValue().total_amount > this.dueBalanceCurrecy;
}



validateAccountNumber = () =>{

  let campo = this.form.controls.accountNumber.value;

   if(this.account?.bank?.name != 'Zelle'){
   
     if(campo &&  /^\d+$/.test(campo) && campo.length > 5){

       let valueCampo = campo.substr(0, 6);

       this.form.get('accountNumber').setValue(valueCampo);


     this.validationAccountNumber = true;

   }else{

     this.validationAccountNumber = false;
     

   }
 }



}


moveCursorToEnd(event: any) {
  const input = event.target;
  input.selectionStart = input.selectionEnd = input.value.length;
}
}


