import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import { mobiscroll, MbscCalendarOptions } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import * as alertifyjs from 'alertifyjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Subscription } from 'rxjs';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-validate-phone',
  templateUrl: './validate-phone.component.html',
  styleUrls: ['./validate-phone.component.css']
})
export class ValidatePhoneComponent implements OnInit,OnDestroy {

  errorMsgValidatTel:any;
  sentValidateTlf = false;
  validateErrorFormat = false;
  activeModalValidateTlf= false;
  centerModal:any;
  code:any;
  data:any;
  loadingSentValidateTlf:any;
  loadingValidateCode:any;
  sentValidateErrorTlf:any;
  showValidateTel : any;
  closeResult: any;

  @Output() setear = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() set selected(data:any) {
    if (data) {
      
      this.centerModal = true;

    }
   
  }


  @Input() set config(data:any) {

    if (data) {
      this.data = data;
      this.sendMsgValidateTel(data);
    }
   
  }


	constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private _user:UserService,
    private router: Router,
    public _auth: AuthService

  ) { }

      ngOnInit(): void {

        this._user.showValidateTel$.subscribe((response:any) => {
          if (response) {
              //console.clear();
              console.log(response);
          }
         });
      }


      
    sendMsgValidateTel = async (tel:any) =>{
      let allCountryRegex = /^(\+\d{1,3}( )?)((\(\d{1,3}\))|\d{1,3})[- .]?\d{3,4}[- .]?\d{4}$/;
      this.sentValidateErrorTlf = false;
      this.validateErrorFormat = false;
      this.loadingSentValidateTlf = true;
      if(allCountryRegex.test(tel)) {

        try {

          const params = {
                'phone':tel,
                'client':'CrediCanguro'
          }

          let result = await this._user.getSendsms(params);

          if (!(result?.error)) {
      
            this.sentValidateTlf = true;
  
  
          }else{
  
            this.sentValidateErrorTlf = true;
  
            this.loadingSentValidateTlf = false;
          }
          this.loadingSentValidateTlf = false;
  
  

        } catch (error) {

          this.loadingSentValidateTlf = false;
          this.sentValidateTlf = false;
          
        }


    
      } else {
        this.validateErrorFormat = true;
        this.loadingSentValidateTlf = false;
      }   
        
    }


    getCodeValidateTel = async (tel:any,code:any)  =>{
      this.loadingValidateCode = true;
      try {

        const params = {
              'phone':tel,
               code
          
        }

        let result = await this._user.getVerificate(params);

        if(result?.valid){
                    
/*
          swal.fire({
            html: this.i18n.transform('Validación exitosa')
          });*/

         this.setChange(this.data);

        }else{
            this.errorMsgValidatTel = 'Codigo Invalido'
            this.code = null;
        }
        this.loadingValidateCode = false;


      } catch (error) {

    
      }

    }

      

      ngOnDestroy = ():void => {

      }


      setClose(){
        this.close.emit(null);
        this.centerModal = false;
      }


      setChange(e:any){
        this.setear.emit(e);
        this.centerModal = false;
      }

      openLg(content:any) {
        this.modalService.open(content, { size: 'lg' });
      }
    
      
}
