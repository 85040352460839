<div class="mt-md-3 p-3">

    <div class="row ">
        <div class=" col-12">
            <ng-container *ngIf="!loading && person?.person" class="mt-2">

                <div *ngIf="!acept" class=" col-12  mt-4 ">

                    <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">

                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Condiciones Generales de <strong>&ldquo;DISTRICUEROS EL CANGURO, C.A&rdquo;.</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Las presentes Condiciones regulan.</p>
                        <ul>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">El uso de <strong>Credicanguro</strong></p>
                            </li>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Y cualquier otro Contrato o relaci&oacute;n jur&iacute;dica conexos celebrados con <strong>EL TITULAR, LA LICENCIANTE Y LAS LICENCIATARIAS</strong> de forma jur&iacute;dicamente vinculante.</p>
                            </li>
                        </ul>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Las palabras en may&uacute;scula se definen en la secci&oacute;n correspondiente espec&iacute;fica del presente documento.</p>
                        <!-- <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">de forma jur&iacute;dicamente vinculante. Las palabras en may&uacute;sculas se definen en la secci&oacute;n correspondiente espec&iacute;fica del presente documento.</p> -->
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Los Usuarios y/o consumidores finales que act&uacute;an en este acto como persona natural deben leer atentamente el presente documento.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Aunque la relaci&oacute;n contractual relativa a estos Productos y servicios en su totalidad se establece &uacute;nicamente entre el Titular, la Licenciante, las Licenciatarias y los Usuarios. Asimismo, los Usuarios reconocen y
                            aceptan que, cuando <strong>Credicanguro</strong> se les haya facilitado a trav&eacute;s de las aplicaciones App Store y Play Store, podr&aacute; exigir el cumplimiento de las presentes Condiciones como terceros beneficiarios.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Credicanguro</strong> es ofrecida por:</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">&ldquo;<span size="2" style="font-size:13px;"><strong>DISTRICUEROS EL CANGURO, C.A&rdquo;.&nbsp;</strong></span><span size="2" style="font-size:13px;">Con domicilio fiscal en</span><span size="2" style="font-size:13px;"><strong>&nbsp;</strong></span>
                            <span size="2" style="font-size:13px;">la siguiente direcci&oacute;n</span><span size="2" style="font-size:13px;"><strong>:&nbsp;</strong></span>Calle 25 entre Carreras 21 y 22, C.C. Cosmos Nivel Planta Baja (PB), Local 1B4,
                            1B5 y 1B6, Sector Centro de la Ciudad de Barquisimeto del Estado Lara.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Identificada con el Registro &Uacute;nico de Informaci&oacute;n Fiscal <strong>(R.I.F.), Nro.J-30856520-2</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">A trav&eacute;s de<strong>&nbsp;EL LICENCIANTE</strong> o<strong>&nbsp;LAS LICENCIATARIAS,</strong> Empresas que act&uacute;an en nombre propio y otras en representaci&oacute;n del licenciante quien es titular de la marca &ldquo;CANGURO&rdquo;.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Correo electr&oacute;nico de contacto del Titular:</strong> soporte@cangurovenezuela.com</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">&laquo;<strong>Credicanguro</strong>&raquo; hace referencia a</p>
                        <ul>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Aplicaciones para m&oacute;viles, tabletas y otros sistemas de dispositivos inteligentes;</p>
                            </li>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">las Interfaces de Programaci&oacute;n de Aplicaciones (API);</p>
                            </li>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">El Servicio.</p>
                            </li>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Cualesquiera aplicaciones, ficheros de muestra y de contenidos, c&oacute;digo fuente, scripts, conjuntos de instrucciones o software incluidos como parte del Servicio, as&iacute; como cualquier documentaci&oacute;n conexa;</p>
                            </li>
                        </ul>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Los siguientes documentos se incorporan por referencia en las presentes Condiciones:</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Lo que el Usuario deber&iacute;a saber de un vistazo</strong></p>
                        <ul>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">El Servicio/<strong>&nbsp;Credicanguro</strong> solo est&aacute; destinado a los Usuarios y/o Consumidores finales que act&uacute;an en este acto como persona natural.</p>
                            </li>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>La utilizaci&oacute;n de Credicanguro y del Servicio est&aacute; restringida en funci&oacute;n de la edad</strong>: para acceder a <strong>Credicanguro</strong> y a sus Servicios y utilizarlos es preciso ser mayor de edad
                                    seg&uacute;n la ley aplicable.</p>
                            </li>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Extranjeros con residencia fiscal.</p>
                            </li>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Personas naturales civilmente h&aacute;bil.</p>
                            </li>
                        </ul>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><br></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>CONTRATO DE ADHESI&Oacute;N.</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>EL USUARIO</strong> declara que al aplicar a trav&eacute;s de la plataforma de <strong>Credicanguro</strong> al sistema de servicio y compra a plazo y/o cuotas de bienes y servicios ofrecidos por <strong>EL LICENCIANTE</strong>                            o <strong>LAS</strong> <strong>LICENCIATARIAS</strong>, conoce y acepta los t&eacute;rminos y condiciones que rigen estas operaciones y por tanto se adhiere y se compromete a respetar las siguientes cl&aacute;usulas:</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>PRIMERA: EL USUARIO</strong> declara que ha solicitado a trav&eacute;s del portal de <strong>Credicanguro</strong> la aprobaci&oacute;n para efectuar los servicios y/o compras a plazo y/o cuotas por <strong>EL LICENCIANTE</strong>                            o <strong>LAS LICENCIATARIAS. EL LICENCIANTE&nbsp;</strong>o<strong>&nbsp;LAS LICENCIATARIAS&nbsp;</strong>una vez recibida la aprobaci&oacute;n de la plataforma de <strong>Credicanguro</strong>, dar&aacute; en venta y/o servicio
                            a plazo y/o cuotas a <strong>EL USUARIO</strong>, el/los bienes(es) o el/los servicio(s) detallados en la factura correspondiente.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>SEGUNDA: EL USUARIO</strong> declara que acepta las ventas y/o servicios a plazo y/o cuotas que le ofrece <strong>EL LICENCIANTE&nbsp;</strong>o<strong>&nbsp;LAS LICENCIATARIAS</strong>, en los t&eacute;rminos que se establecen
                            en el presente contrato y en el cronograma de pagos fijados en la aplicaci&oacute;n y, en consecuencia, se obliga a pagar oportunamente en el plazo y/o cuotas y la totalidad del precio de los bienes o los servicios adquiridos.
                            <strong>EL USUARIO</strong> pagar&aacute; una inicial y solicitar&aacute; el plazo y/o cuotas a trav&eacute;s de los mecanismos de pago que disponga <strong>EL LICENCIANTE&nbsp;</strong>o<strong>&nbsp;LAS LICENCIATARIAS&nbsp;</strong>y
                            utilizar&aacute; la tasa del Banco Central de Venezuela del d&iacute;a de pago para el c&aacute;lculo del plazo y/o las cuotas correspondientes. <strong>EL USUARIO</strong> podr&aacute; solicitar a <strong>EL LICENCIANTE&nbsp;</strong>o<strong>&nbsp;LAS LICENCIATARIAS&nbsp;</strong>la
                            nota de d&eacute;bito o cr&eacute;dito que corresponda en casos de variaci&oacute;n de la tasa de cambio.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>TERCERA:</strong> El plazo del presente contrato tiene una vigencia hasta que <strong>EL USUARIO</strong> haya cancelado en su totalidad la &uacute;ltima cuota del pago acordado. Adem&aacute;s, <strong>EL USUARIO</strong>                            pagar&aacute; una inicial y tendr&aacute; plazo y/o cuotas dentro de un lapso de 30 d&iacute;as seg&uacute;n el cronograma de pagos indicado en el portal de <strong>Credicanguro</strong>.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>CUARTA: EL USUARIO&nbsp;</strong>conviene expresamente<strong>&nbsp;</strong>con<strong>&nbsp;EL LICENCIANTE y LAS LICENCIATARIAS,&nbsp;</strong>que, motivado por el incumplimiento en el pago oportuno de cada cuota en el
                            plazo acordado o de ser el caso de efectuarlo extempor&aacute;neo el pago. <strong>EL</strong> <strong>USUARIO</strong> autoriza a <strong>EL LICENCIANTE y LAS LICENCIATARIAS,</strong> una vez agotada su gesti&oacute;n de cobranza,
                            a reportar la incidencia del hecho a la aplicaci&oacute;n valoradora xxxxxxxx</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>QUINTA: EL USUARIO</strong> acepta y autoriza expresamente a que <strong>EL LICENCIANTE, LAS LICENCIATARIAS&nbsp;</strong>o un tercero (agencia de cobranza o adquiriente &uacute;ltimo de la deuda) se comuniquen con Usted
                            para notificar avisos de cobro y/o cualquier informaci&oacute;n relacionada con la compra o servicios a plazo y/o cuotas efectuada, a trav&eacute;s de llamadas a su n&uacute;mero m&oacute;vil, mensajes de SMS, mensajes de WhatsApp,
                            correos electr&oacute;nicos y env&iacute;en comunicaciones en f&iacute;sico a la direcci&oacute;n aportada por Usted al portal de <strong>Credicanguro</strong> para la creaci&oacute;n de su usuario. En este sentido, <strong>EL USUARIO</strong>                            expresamente declara que esta informaci&oacute;n proporcionada para la creaci&oacute;n de su perfil podr&aacute; ser compartida entre<strong>&nbsp;EL LICENCIANTE, LAS LICENCIATARIAS</strong> y <strong>TERCEROS</strong> para
                            fines relacionados con las compras o servicios realizados.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>SEXTA: EL USUARIO</strong> declara que ha examinado los bienes que se le dan en ventas y servicios, y ha verificado que los mismos se encuentran nuevos, sin detalles y en total funcionamiento, por lo que lo recibe a su
                            entera y cabal satisfacci&oacute;n. Ser&aacute; responsabilidad de <strong>EL LICENCIANTE O LAS LICENCIATARIAS&nbsp;</strong>responder por las garant&iacute;as de buen uso y funcionamiento de acuerdo con las pol&iacute;ticas
                            de dichos establecimientos que <strong>EL USUARIO</strong> declara conocer.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>S&Eacute;PTIMA: EL LICENCIANTE</strong> y<strong>&nbsp;LAS LICENCIATARIAS</strong> pone a disposici&oacute;n de <strong>EL USUARIO</strong> el correo electr&oacute;nico soporte@cangurovenezuela.com, para todos los reclamos
                            que tenga o pueda tener con motivo de los servicios y/o compras a plazo y/o cuotas ejecutadas con <strong>EL LICENCIANTE&nbsp;</strong>o<strong>&nbsp;LAS LICENCIATARIAS.&nbsp;</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">En la ciudad de Caracas, a la fecha de su aceptaci&oacute;n.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>CONDICIONES DE USO</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Salvo que se establezca lo contrario, las condiciones de uso detalladas en esta secci&oacute;n se aplicar&aacute;n de forma general al uso de <strong>Credicanguro</strong>.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">En situaciones concretas pueden aplicarse condiciones de uso o de acceso individuales o adicionales y en tales supuestos se indicar&aacute;n de forma adicional en el presente documento.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Al utilizar <strong>Credicanguro</strong>, los Usuarios confirman que cumplen los siguientes requisitos.</p>
                        <ul>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Los Usuarios deber&aacute;n entrar en la categor&iacute;a de Consumidores;</p>
                            </li>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Los Usuarios deber&aacute;n ser mayores de edad con arreglo a la ley aplicable;</p>
                            </li>
                        </ul>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Registro de la cuenta</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Para utilizar el Servicio los Usuarios deber&aacute;n registrar o crear una cuenta de Usuario, facilitando todos los datos o la informaci&oacute;n exigidos de forma completa y fidedigna. En caso de no hacerlo el Servicio no estar&aacute;
                            disponible.
                        </p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Los Usuarios son responsables de la custodia de sus credenciales de acceso con garant&iacute;as de confidencialidad y seguridad. Por este motivo, los Usuarios tambi&eacute;n deber&aacute;n escoger contrase&ntilde;as que cumplan
                            los m&aacute;s altos est&aacute;ndares de fortaleza permitidos por <strong>Credicanguro</strong>.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Al registrarse, los Usuarios acuerdan asumir plena responsabilidad por todas las actividades que tengan lugar bajo su nombre de usuario y su contrase&ntilde;a.<br>&nbsp;Los Usuarios deber&aacute;n informar al Titular de forma inmediata
                            y sin ambig&uuml;edades, usando para ello los datos de contacto indicados en el presente documento, en caso de que crean que su informaci&oacute;n personal, incluyendo, con car&aacute;cter enunciativo y no limitativo, las cuentas
                            de Usuario, las credenciales de acceso o los datos personales, han sido vulnerados, revelados de forma indebida o robados.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Condiciones para el registro de cuentas</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">El registro de cuentas de Usuario en <strong>Credicanguro</strong> est&aacute; sometido a las condiciones establecidas a continuaci&oacute;n. Al registrarse, los Usuarios consienten en cumplir tales condiciones.</p>
                        <ul>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">No se permiten las cuentas registradas por bots o por cualquier otro m&eacute;todo automatizado.</p>
                            </li>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Salvo que se especifique lo contrario, cada Usuario deber&aacute; registrar solo una cuenta.</p>
                            </li>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Salvo que se autorice de forma expresa, las cuentas de Usuario no podr&aacute;n compartirse con otras personas.</p>
                            </li>
                        </ul>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Cancelaci&oacute;n de la cuenta</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Los Usuarios podr&aacute;n cancelar sus cuentas y dejar de utilizar el Servicio en cualquier momento de la siguiente forma:</p>
                        <ul>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Contactando directamente con el Titular a trav&eacute;s de los datos de contacto facilitados en el presente documento.</p>
                            </li>
                        </ul>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Suspensi&oacute;n y eliminaci&oacute;n de la cuenta</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">El Titular se reserva el derecho, a su entera discreci&oacute;n, de suspender o eliminar las cuentas de Usuarios en cualquier momento y sin preaviso, si las considera inapropiadas, ofensivas o entiende que incumplen las presentes
                            Condiciones.
                        </p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">La suspensi&oacute;n o eliminaci&oacute;n de cuentas de Usuario no dar&aacute; derecho a los Usuarios a exigir ninguna compensaci&oacute;n, indemnizaci&oacute;n por da&ntilde;os y perjuicios o reembolso.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">La suspensi&oacute;n o eliminaci&oacute;n de cuentas debido a causas imputables al Usuario no eximir&aacute; a dicho Usuario de abonar las tarifas o los precios que sean de aplicaci&oacute;n.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Contenido en Credicanguro</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Salvo que se especifique lo contrario o se pueda reconocer de forma clara, todos los contenidos disponibles en <strong>Credicanguro</strong> son propiedad del Titular o son proporcionados por<strong>&nbsp;EL LICENCIANTE y LAS LICENCIATARIAS.</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">El Titular se compromete a actuar con la m&aacute;xima diligencia para velar por que los contenidos proporcionados en&nbsp;<strong>Credicanguro</strong> no infrinjan ninguna disposici&oacute;n legal ni vulneren los derechos de terceros.
                            Sin embargo, no siempre ser&aacute; posible conseguir dicho objetivo.<br>&nbsp;En tales supuestos, sin perjuicio de las prerrogativas legales de que dispongan los Usuarios para hacer cumplir sus derechos, se ruega a los Usuarios
                            que comuniquen las quejas en este sentido utilizando los datos de contacto facilitados en el presente documento.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Derechos relativos a los contenidos en Credicanguro</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">El Titular se reserva todos los derechos de propiedad intelectual sobre la totalidad de dichos contenidos.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Por consiguiente, los Usuarios no podr&aacute;n utilizar esos contenidos de formas que no sean necesarias o est&eacute;n impl&iacute;citas en el uso adecuado del Servicio.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">En particular, pero sin limitaciones, los Usuarios no podr&aacute;n copiar, descargar, compartir (m&aacute;s all&aacute; de los l&iacute;mites establecidos m&aacute;s adelante), modificar, traducir, transformar, publicar, transmitir,
                            vender, sublicenciar, editar, transferir/ceder a terceros o crear obras derivadas de los contenidos disponibles en <strong>Credicanguro</strong>.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">En los casos en que se establezca de forma expresa en <strong>Credicanguro</strong>, el Usuario podr&aacute; descargar, copiar y/o compartir cualquier contenido disponible a trav&eacute;s de <strong>Credicanguro</strong> &uacute;nicamente
                            para su uso personal y no comercial y siempre y cuando se efect&uacute;en correctamente los reconocimientos de derechos de autor y todos los dem&aacute;s reconocimientos solicitados por el Titular.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Las limitaciones o excepciones de los derechos de autor establecidas por ley no se ver&aacute;n afectadas.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Acceso a recursos externos</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">A trav&eacute;s de <strong>Credicanguro</strong> los Usuarios podr&aacute;n acceder a recursos externos proporcionados por terceros. Los Usuarios reconocen y aceptan que el Titular no tiene ning&uacute;n control sobre dichos recursos
                            y que, por tanto, no es responsable de sus contenidos y disponibilidad.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Las condiciones aplicables a los recursos proporcionados por terceros, incluidas las aplicables a cualquier posible concesi&oacute;n de derechos sobre el contenido, se derivan de los t&eacute;rminos y condiciones de dichos terceros
                            o, a falta de estos, de las leyes aplicables.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Usos aceptables</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Credicanguro</strong> y el Servicio solo podr&aacute;n utilizarse dentro del &aacute;mbito para el cual se proporcionan, con arreglo a las presentes Condiciones y a la legislaci&oacute;n aplicable.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Los Usuarios ser&aacute;n los &uacute;nicos responsables de asegurarse de que su utilizaci&oacute;n de <strong>Credicanguro</strong> y/o del Servicio no infringe ninguna ley o reglamento ni vulnera derechos de terceros.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Por consiguiente, <strong>el Titular, EL LICENCIANTE y LAS LICENCIATARIAS, se reserva el derecho a adoptar las medidas oportunas para proteger sus intereses leg&iacute;timos, incluyendo denegar a los Usuarios el acceso a Credicanguro o al Servicio, resolver contratos, denunciar conductas inadecuadas llevadas a cabo a trav&eacute;s de Credicanguro a o del Servicio a las autoridades competentes - tales como las autoridades judiciales o administrativas - siempre que los Usuarios realicen o se sospechen que han realizado cualquiera de las siguientes actividades:</strong></p>
                        <ul>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Infracciones de las leyes, los reglamentos y/o de las presentes Condiciones;</p>
                            </li>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Vulneraci&oacute;n de los derechos de terceros;</p>
                            </li>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Causar un perjuicio considerable a los intereses leg&iacute;timos del Titular;</p>
                            </li>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Ofender al Titular o a alg&uacute;n tercero.</p>
                            </li>
                        </ul>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Licencia de software</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">El software integrado en <strong>Credicanguro</strong> o relacionado con esta se proporciona con arreglo a una licencia de &laquo;algunos derechos reservados&raquo;.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Esto significa que se conceden a los Usuarios derechos amplios, incluyendo, con car&aacute;cter enunciativo y no limitativo, los derechos a utilizar, ejecutar, copiar o distribuir el software, en la medida determinada por dicha
                            licencia.
                        </p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Las condiciones de dicha licencia prevalecer&aacute;n siempre sobre las disposiciones contrarias, divergentes o incompatibles de las presentes Condiciones.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Los Usuarios podr&aacute;n encontrar informaci&oacute;n adicional relativa a las condiciones de la licencia en la secci&oacute;n correspondiente de<strong>&nbsp;Credicanguro</strong>.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Condiciones de uso de la API</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Los Usuarios podr&aacute;n acceder a sus datos relativos a <strong>Credicanguro</strong> a trav&eacute;s de la Interfaz de Programaci&oacute;n de Aplicaciones (API). Cualquier uso de la API, incluido el uso de la API mediante un producto/servicio
                            de terceros que acceda a <strong>Credicanguro</strong> se regir&aacute; por las presentes Condiciones y, adem&aacute;s, por las siguientes condiciones espec&iacute;ficas:</p>
                        <ul>
                            <li>
                                <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">El Usuario entiende y acuerda expresamente que el Titular no tendr&aacute; ninguna responsabilidad y no ser&aacute; considerado responsable de cualesquiera da&ntilde;os y perjuicios o p&eacute;rdidas derivados de la utilizaci&oacute;n
                                    de la API por parte del Usuario o de su uso de cualesquiera productos/servicios de terceros que accedan a datos a trav&eacute;s de la API.</p>
                            </li>
                        </ul>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Responsabilidad y exenci&oacute;n de responsabilidad</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Salvo que se establezca o acuerde con los Usuarios lo contrario de forma expresa, se excluye, limita y/o reduce la responsabilidad del Titular por los da&ntilde;os y perjuicios relativos a la ejecuci&oacute;n del Contrato hasta
                            el m&aacute;ximo permitido por la ley aplicable.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Disposiciones comunes</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>No renuncia</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">La falta de ejercicio de cualquier derecho o el hecho de no invocar una disposici&oacute;n en virtud de las presentes Condiciones no constituir&aacute;n una renuncia a dicho derecho o dicha disposici&oacute;n. No se considerar&aacute;
                            que ninguna renuncia constituya a su vez una renuncia adicional o continuada a dicho t&eacute;rmino o a cualquier otro t&eacute;rmino.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Interrupci&oacute;n del servicio</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Para garantizar el mejor nivel de servicio posible, el Titular se reserva el derecho a interrumpir el Servicio para labores de mantenimiento, actualizaciones del sistema o cualesquiera otros cambios, informando adecuadamente a
                            los Usuarios.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Dentro de los l&iacute;mites de la ley, el Titular tambi&eacute;n podr&aacute; decidir suspender o dejar de prestar por completo el Servicio. Si el servicio deja de prestarse, el Titular cooperar&aacute; con los Usuarios para permitirles
                            retirar Datos personales o informaci&oacute;n y respetar&aacute; los derechos de los Usuarios relativos al uso continuado/la continuaci&oacute;n en el uso del producto y/o la compensaci&oacute;n, seg&uacute;n establezca la
                            ley aplicable.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Asimismo, el Servicio podr&aacute; no estar disponible debido a motivos fuera del control razonable del Titular, como la &laquo;fuerza mayor&raquo; (p.ej. aver&iacute;as en las infraestructuras o apagones, etc.).</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Reventa del Servicio</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Los Usuarios no reproducir&aacute;n, duplicar&aacute;n, copiar&aacute;n, vender&aacute;n, revender&aacute;n o explotar&aacute;n ninguna parte de <strong>Credicanguro</strong> y de su Servicio sin la autorizaci&oacute;n previa, expresa
                            y por escrito del Titular, concedida ya sea directamente o a trav&eacute;s de un programa de reventa leg&iacute;timo.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Pol&iacute;tica de privacidad.</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Para obtener m&aacute;s informaci&oacute;n sobre la utilizaci&oacute;n de sus Datos personales, los Usuarios podr&aacute;n referirse a la pol&iacute;tica de privacidad de <strong>Credicanguro</strong>.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><br>&nbsp;</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><br>&nbsp;</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Derechos de propiedad intelectual</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Sin perjuicio de cualesquiera disposiciones m&aacute;s espec&iacute;ficas de las presentes Condiciones, los derechos de propiedad intelectual, tales como los derechos de autor, derechos derivados de marcas registradas, derechos
                            de patentes y derechos de dise&ntilde;os relativos a <strong>Credicanguro</strong> son propiedad exclusiva del Titular o de su<strong>&nbsp;LICENCIANTE&nbsp;</strong>y/o<strong>&nbsp;LAS LICENCIATARIAS</strong>, y est&aacute;n protegidos
                            por las leyes en vigor en materia de marcas y los tratados internacionales relacionados.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Todas las marcas registradas - sean denominativas o gr&aacute;ficas - y cualesquiera otras marcas, nombres comerciales, marcas de servicio, signos denominativos, ilustraciones, im&aacute;genes o logotipos que aparezcan en relaci&oacute;n
                            con <strong>Credicanguro,</strong> son y seguir&aacute;n siendo propiedad exclusiva del Titular, <strong>EL LICENCIANTE y LAS LICENCIATARIAS,</strong> y est&aacute;n protegidos por las leyes en vigor en materia de marcas y los tratados
                            internacionales relacionados.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Cambios de las presentes Condiciones</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">El Titular se reserva el derecho a cambiar o modificar de cualquier otro modo las presentes Condiciones en cualquier momento. En tales casos, el Titular, <strong>EL LICENCIANTE y LAS LICENCIATARIAS</strong> informar&aacute; adecuadamente
                            a los Usuarios de esos cambios.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Dichos cambios solo afectar&aacute;n a la relaci&oacute;n con los Usuarios a partir de la fecha comunicada a los Usuarios.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>La continuidad en el uso del Servicio indicar&aacute; la aceptaci&oacute;n por parte de los Usuarios de las Condiciones modificadas.</strong> Si los Usuarios no desean quedar vinculados por estos cambios, deber&aacute;n
                            dejar de usar el Servicio y podr&aacute;n resolver el Contrato.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">La versi&oacute;n aplicable previa regular&aacute; la relaci&oacute;n antes de la aceptaci&oacute;n del Usuario. Los Usuarios podr&aacute;n obtener cualquier versi&oacute;n previa del Titular.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Si lo exige la ley, el Titular notificar&aacute; por adelantado a los Usuarios la fecha en que se har&aacute;n efectivas las Condiciones modificadas.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Cesi&oacute;n del contrato</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">El Titular se reserva el derecho a transferir, ceder, disponer mediante novaci&oacute;n o subcontratar cualquiera de los derechos o las obligaciones establecidos con arreglo a las presentes Condiciones, teniendo en cuenta los intereses
                            leg&iacute;timos de los Usuarios.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Las disposiciones relativas a los cambios de las presentes Condiciones se aplicar&aacute;n mutatis mutandis.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Los Usuarios no podr&aacute;n ceder ni transferir sus derechos u obligaciones con arreglo a las presentes Condiciones en modo alguno, salvo con el permiso por escrito del Titular.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Contacto</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Todas las comunicaciones relativas a la utilizaci&oacute;n de <strong>Credicanguro</strong> deber&aacute;n remitirse utilizando los datos de contacto se&ntilde;alados en el presente documento.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Posibilidad de separar una disposici&oacute;n</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">En el caso de que cualquier disposici&oacute;n de las presentes Condiciones fuera declarada o se convirtiera en inv&aacute;lida o inejecutable con arreglo a la ley aplicable, la invalidez o inejecutabilidad de dicha disposici&oacute;n
                            no afectar&aacute;n a la validez de las disposiciones restantes, que continuar&aacute;n gozando de plena vigencia y efectividad.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Ley aplicable</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">Las presentes Condiciones se rigen por las leyes del lugar en el que tenga su sede el Titular, seg&uacute;n lo declarado en la secci&oacute;n correspondiente del presente documento, sin tener en cuenta los principios sobre conflictos
                            de leyes.</p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><strong>Fuero jurisdiccional</strong></p>
                        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;">La competencia exclusiva para resolver cualquier controversia resultante de las presentes Condiciones o relacionada con estas corresponde a los tribunales del lugar en el que se encuentre el domicilio social del Titular, como se
                            indica en la secci&oacute;n correspondiente del presente documento.</p>
                        <p style="margin-bottom: 0.28cm;line-height: 108%;text-align: left;background: transparent;"><br>&nbsp;</p>

                    </div>
                    <div class="text-center row mt-4" *ngIf="!hidenApro">
                        <div class="col-12 col-md-4 offset-md-4">
                            <div class="d-grid gap-2">
                                <button class="btn btn-block btn-success " (click)="personApproveConditionsPost()">
                                <span >Aceptar</span>
                              </button>
                            </div>
                        </div>
                        <div class="text-secondary small mt-2">Al presionar aceptar, estara de acuerdo con los Terminos y condiciones</div>

                    </div>


                </div>
            </ng-container>



            <ng-container *ngIf="loading || msg">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>



        </div>
    </div>

</div>