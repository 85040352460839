<div class="container mt-2  mt-md-4">

    <ng-container *ngIf="!loading">


        <div class="text-start mt-4 ">
            <small class="text-secondary">

                 {{invoiceCredit?.installmentNexPayment?.scheduledPaymentDate | date:'d MMM, yyyy h:mm a':'es'}}

            </small>

            <h3 class="text-dark fw-bold mt-2  mb-0">
                {{shops_obj[invoiceCredit?.provider_transaction?.shop]}}
            </h3>
        </div>


        <div class="mt-4">

            <div class="d-flex">

                <div (click)="tp = 1" [ngClass]="{'fw-bold': tp == 1}" class=" cursor-pounter text-primary" style="width: 100px;">
                    Detalles
                </div>
                <div (click)="tp = 2" [ngClass]="{'fw-bold': tp == 2}" class="cursor-pounter text-primary" style="width: 100px;">
                    Cronograma
                </div>
            </div>
            <hr class="pt-0 mt-1">

        </div>

        <ng-container *ngIf="tp==1">

            <div class=" fz-18">
                <span class="fw-bold pe-1">Crédito</span>
                <span> (ID: {{invoiceCredit.identifier}})</span>

            </div>

            <div class=" d-flex w-100 mt-3">
                <div class="fw-bold small ">Compra total</div>
                <div class="fw-bold fz-18 ms-auto text-end"> {{invoiceCredit.totalAmount}} USD</div>

            </div>

            <div class=" d-flex w-100 mt-3">
                <div class="fw-bold small ">Total intereses (0.00% anual)</div>
                <div class="fw-bold fz-18 ms-auto text-end"> + 0.00 USD</div>
            </div>


            <div class=" d-flex w-100 mt-3 mb-3">
                <div class="fw-bold small ">Total pagado</div>
                <div class="fw-bold fz-18 ms-auto text-end"> {{invoiceCredit.totalPaymentConcile }} USD</div>
            </div>

            <hr class="pt-0 mt-1">

            <div class="mt-4">

                <div>

                    <i class="fas fa-headset orange text-secondary"></i>
                    <span class=" ps-2 orange small"> Ayuda o soporte</span>

                </div>


                <div class="mt-3">
                    <i class="far fa-question-circle orange text-secondary"></i>
                    <span class=" ps-2 orange small"> Preguntas frecuentes</span>
                </div>


            </div>

        </ng-container>
        <!-- <pre>{{ invoiceCredit | json }}</pre> -->

        <ng-container *ngIf="tp==2">

            <div class="mt-3">
                <ngb-progressbar type="info" [value]=" bard(invoiceCredit.dueBalance,invoiceCredit.totalAmount)">Copying file <b>{{ bard(invoiceCredit.dueBalance,invoiceCredit.totalAmount)}}%</b>...</ngb-progressbar>
            </div>

            <div class=" d-flex w-100 mt-4">
                <div class=" ">
                    <div class="text-secondary small">
                        Pagado a la fecha
                    </div>
                    <div class="fw-bold fz-18 ">

                        {{invoiceCredit.totalPaymentConcile }} USD

                    </div>

                </div>
                <div class="ms-auto text-end">
                    <div class=" ">
                        <div class="text-secondary small">
                            Pendiente por pagar
                        </div>
                        <div class="fw-bold fz-18 ">
                            {{invoiceCredit.dueBalance}} USD


                        </div>

                    </div>


                </div>



            </div>

            <div class=" d-flex w-100 mt-2">

                <div class=" ">
                    <div class="text-secondary small">
                        Total pagos por conformar
                    </div>
                    <div class="fw-bold text-secondary fz-18 ">

                        {{mounto_por_conciliar }} USD

                    </div>

                </div>

            </div>


            <div class="d-flex w-100 mt-3 mb-3">

                <div>
                    <i style="color: orange;" class="fas fa-redo orange "></i>

                </div>
                <div class=" ">
                    <span class="  ps-2  ">Autopago: </span>
                    <span class="  ps-2 text-danger ">Inactivo </span>

                    <div class="pt-2">
                        <a href="" class="ps-2 mt-2  text-secondary">Gestionar autopago </a>
                    </div>


                </div>
            </div>

            <hr class="pt-0 mt-1">

            <h5 class="text-dark fw-bold mt-2  mb-1">
                Cronograma
            </h5>


            <div class="card   mt-3 " [ngClass]="{'cmt2': invoiceCredit?.installmentNexPayment?.code != item?.installMent?.code,'mt2': invoiceCredit?.installmentNexPayment?.code == item?.installMent?.code,'pagada':item?.installMent?.payment_status?.code == 'PaymentComplete'}"
                *ngFor="let item of invoiceCredit?.installMent">
                <!-- <pre>{{ item | json }}</pre> -->

                <div class="card-body">
                    <div class="flex-grow-1 bd-highlight">

                        <div class="d-flex w-100 mt-2">
                            <div>

                                <span class=" fw-bold">{{ item?.installMent ?.order
                                    < 1 ? 'Inicial': 'Cuota '+item?.installMent ?.order}}</span>


                            </div>
                            <div class="text-end ms-auto">
                                <div class="fw-bold fz-18 ms-auto text-end" [ngClass]="{'text-danger': item.expired && item?.installMent?.payment_status?.code != 'PaymentComplete'}"> {{item?.installMent?.dueBalance == 0 ? item?.installMent?.amount : item?.installMent?.dueBalance}} USD</div>
                            </div>
                        </div>


                        <div class="d-flex w-100 mt-2">
                            <div>
                                <span *ngIf="mounto_por_conciliar >= item?.installMent?.dueBalance && item?.installMent?.payment_status?.code != 'PaymentComplete' && invoiceCredit?.installmentNexPayment?.code == item?.installMent?.code" class="text-secondary small">Pagos pendientes por conformar <b> {{mounto_por_conciliar }} USD</b></span>

                                <span *ngIf="mounto_por_conciliar < item?.installMent?.dueBalance && item?.installMent?.payment_status?.code != 'PaymentComplete' && invoiceCredit?.installmentNexPayment?.code == item?.installMent?.code" class="orange small">Próximo pago {{item?.installMent?.scheduledPaymentDate | date:'d MMM, yyyy':'es'}}</span>
                                <span *ngIf="item?.installMent?.payment_status?.code != 'PaymentComplete' && invoiceCredit?.installmentNexPayment?.code != item?.installMent?.code" class="text-secondary small">Pagar {{item?.installMent?.scheduledPaymentDate | date:'d MMM, yyyy':'es'}}</span>
                                <span *ngIf="item?.installMent?.payment_status?.code == 'PaymentComplete'" class="text-secondary small">Pagada {{item?.installMent?.payment_date | date:'d MMM, yyyy':'es'}}</span>

                            </div>


                            <div class="text-end ms-auto" *ngIf="mounto_por_conciliar < item?.installMent?.dueBalance">
                                <button *ngIf="item?.installMent?.payment_status?.code != 'PaymentComplete' && invoiceCredit?.installmentNexPayment?.code == item?.installMent?.code" type="button" class="btn btn-primary" [routerLink]="['/payment/',invoiceCredit?.code,invoiceCredit?.installmentNexPayment?.code]">Pagar</button>
                                <span *ngIf="item?.installMent?.payment_status?.code == 'PaymentComplete'" class="text-secondary small">Pagado </span>

                            </div>
                        </div>




                    </div>


                </div>
            </div>

        </ng-container>




        <!-- 
        <div class="card cmt2 mt-3 ">
            <div class="card-body ">


                <div>
                    <span class="ms-2 fw-bold "></span>
                </div>

                <div class="mt-2 ">

                    <div class="d-flex w-100 ">
                        <div>
                            <span class="ms-2 ak-text-color-6 small ">Próximo pago  {{invoiceCredit?.installmentNexPayment?.scheduledPaymentDate | date:'d MMM, yyyy':'es'}}</span>

                        </div>
                        <div class="text-end ms-auto ">
                            <span class="text-dark fw-bold fz-18 ">{{invoiceCredit?.installmentNexPayment?.dueBalance}} USD</span>
                        </div>
                    </div>

                </div>

                <div class="mt-2 ">
                    <div class="d-flex w-100 ">

                        <div>
                            <i style="color: orange; " class="fas fa-redo orange "></i>

                        </div>
                        <div class=" ">
                            <span class=" text-secondary ps-2 ">Próximamente domicilia tu pago </span>
                        </div>
                    </div>

                    <div class="mt-3 ">
                        <ngb-progressbar type="warning " [value]=" bard(invoiceCredit.dueBalance,invoiceCredit.totalAmount) ">Copying file <b>{{ bard(invoiceCredit.dueBalance,invoiceCredit.totalAmount)}}%</b>...</ngb-progressbar>

                    </div>
totalAmount
                </div>



            </div>
        </div> -->







    </ng-container>

</div>

<ng-container *ngIf="loading || loading_buro ">
    <div class="w-100 text-center ">
        <img src="./assets/public/images/loading.gif " alt=" ">
    </div>
</ng-container>